import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import "./Navbar.css";
import { MenuItem, Box, Menu, Tooltip, Button, Container, } from "@mui/material";
import { CART_INITIAL_STATE, CartDets, DashboardComponent, HTTPLASTPART, HTTPS, SUCCESS, UPDATEACTIVESHOPSESSION, printDebugLog } from "../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import { setCartType, setActiveShopHash } from "../../actions";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { snpAxios } from "../global/api";
import { NotificationContainer, NotificationManager } from "react-notifications";
const drawerWidth = 270;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar({ open, toggleDrawer, setComponentIndex, currIndex, handleLoader }) {
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(false);
  const [anchorElShop, setAnchorElShop] = React.useState(false);
  const settings = ['Profile', 'Configuration', 'Change Password', 'Ask For Help', 'Lock', 'Logout'];
  const offlineSettings = ['Profile', 'Configuration', 'Change Password', 'Ask For Help', 'Lock', 'Open Offline A2z', 'Logout'];
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenShopMenu = (event) => {
    setAnchorElShop(event.currentTarget);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)');

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [storeCartType, storeActiveShopHash, storeApiUrl]);


  const handleOpenOffline = () => {
    window.open(HTTPS + CART_INITIAL_STATE.offlineIp + HTTPLASTPART + "#/login", "_blank", "noreferrer");
  };

  const handleCloseUserMenu = (event) => {
    //console.log("handleCloseUserMenu : ",event.currentTarget);
    if (event.currentTarget.id === 'Profile') {
      setComponentIndex(11);
    } else if (event.currentTarget.id === 'Logout') {
      setComponentIndex(13);
    } else if (event.currentTarget.id === 'Change Password') {
      setComponentIndex(15);
    } else if (event.currentTarget.id === 'Lock') {
      setComponentIndex(20);
    } else if (event.currentTarget.id === 'Configuration') {
      setComponentIndex(22);
    } else if (event.currentTarget.id === 'Ask For Help') {
      setComponentIndex(DashboardComponent.HELPMODULE);
    } else if (event.currentTarget.id === 'Open Offline A2z') {
      handleOpenOffline();
    }
    setAnchorElUser(null);
  };

  const getKeyByValue = () => {
    return Object.keys(CartDets).find(key => CartDets[key] === storeCartType);
  }

  const getSelKeyByValue = (value) => {
    //console.log("getSelKeyByValue",value);
    return value.location + "(" + Object.keys(CartDets).find(key => CartDets[key] === value.cartType) + ")";
  }



  const updateCartValue = (selectedHash) => {
    //let selectedHash = e.target.value;
    //console.log(selectedHash);
    if (selectedHash != null) {
      //console.log("updateCartValue",selectedHash);
      handleLoader(false);

      let cartUrl = storeApiUrl.BACKEND_BASE_URL + UPDATEACTIVESHOPSESSION;
      let data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          shopHash: selectedHash.id,
        },
      };
      //  console.log("getRestoDetails  => ",data);
      snpAxios
        .post(cartUrl, data)
        .then((response) => {
          printDebugLog("response  => ", response.data);
          handleLoader(true);
          if (response.data.status === SUCCESS) {
            dispatch(setCartType(selectedHash.cartType));
            let responseShopHash = selectedHash;
            responseShopHash.defScreen = response.data.jcJson.defScreen;
            responseShopHash.companyName = response.data.jcJson.companyName;
            responseShopHash.companyWebAddress = response.data.jcJson.companyWebAddress;
            responseShopHash.companyMail = response.data.jcJson.companyMail;
            responseShopHash.packages = response.data.jcJson.packages;
            responseShopHash.onlineDelvMethod = response.data.jcJson.onlineDelvMethod;
            responseShopHash.validity = response.data.jcJson.validity;
            responseShopHash.startDate = response.data.jcJson.startDate;
            responseShopHash.defBalanceType = response.data.jcJson.defBalanceType;
            dispatch(setActiveShopHash(responseShopHash));
            setComponentIndex(responseShopHash.defScreen);
            NotificationManager.success("", "Changed to " + selectedHash.shop + "\nsuccessfully done.");
          } else {
            NotificationManager.error("Changed to " + selectedHash.shop + "\not happen.Try again!");
          }
        })
        .catch((error) => {
          handleLoader(true);
          printDebugLog("errr ", error);
          NotificationManager.error("Changed to " + selectedHash.shop + "\not happen.Try again!");
          //window.location.href = "#/error";
        });



    }
    setAnchorElShop(null);
  }

  const getTips = () => {
    let data = "";
    if (storeCartType === CartDets.Resto)
      data = "Keyboard Tips!\nAccounting:Ctrl+Alt+a\nBilling:Ctrl+Alt+b\nCateogory:Ctrl+Alt+c\nEmployee:Ctrl+Alt+e\nProfile:Ctrl+Alt+p\nLock Screen:Ctrl+Alt+l\nLive Stock Update:Ctrl+Alt+r";
    else
      data = "Keyboard Tips!\nAccounting:Ctrl+Alt+a\nBilling:Ctrl+Alt+b\nCateogory:Ctrl+Alt+c\nEmployee:Ctrl+Alt+e\nProfile:Ctrl+Alt+p\nSearch Item:Ctrl+Alt+s\nLock Screen:Ctrl+Alt+l";
    return data;
  }

  return (
    <AppBar position="absolute" open={open}>
      <NotificationContainer />
      <Container maxWidth="none">
        <Toolbar disableGutters
          sx={{
            pr: "2px", // keep right padding when drawer closed
            color: "black",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              mr: 2,
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          >
            <button onClick={() => setComponentIndex(CART_INITIAL_STATE.defScreen)} style={{ fontSize: "20px", border: "0", background: "transparent", cursor: "pointer" }}>
              Home
            </button>
          </Typography>
          <HomeIcon
            onClick={() => setComponentIndex(CART_INITIAL_STATE.defScreen)}
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />

          {storeCartType !== CartDets.Resto && <Button variant="contained"
            color="primary" onClick={() => setComponentIndex(DashboardComponent.SEARCHDIALOG)} sx={{
              mr: 2, display: { xs: 'none', md: 'flex' }
            }}>
            Search Item
          </Button>}
          {storeCartType !== CartDets.Resto && <SearchIcon
            onClick={() => setComponentIndex(DashboardComponent.SEARCHDIALOG)}
            sx={{ m: 2, display: { xs: 'flex', md: 'none' } }} />}
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {CART_INITIAL_STATE.listShopHash.length > 1 ?
              <div style={{ fontSize: "12px", marginRight: "15px" }}>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ alignContent: "center" }}
                >Current Shop : {getKeyByValue()}
                  <Tooltip title="Open settings">
                    <IconButton color="inherit" onClick={handleOpenShopMenu} sx={{ p: 0 }}>
                      <Badge color="secondary">
                        <ArrowDropDownIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Typography></div> : <div style={{ fontSize: "12px", marginRight: "15px" }}>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >Current Shop : {getKeyByValue()}</Typography></div>
            }
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            {CART_INITIAL_STATE.listShopHash.length > 1 ?
              <div style={{ fontSize: "8px", marginRight: "5px" }}>
                <Typography
                  component="h1"
                  variant="p"
                  color="inherit"
                  noWrap
                  sx={{ alignContent: "center" }}
                >{getKeyByValue()}
                  <Tooltip title="Open settings">
                    <IconButton color="inherit" onClick={handleOpenShopMenu} sx={{ p: 0 }}>
                      <Badge color="secondary">
                        <ArrowDropDownIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Typography></div> : <div style={{ fontSize: "8px", marginRight: "5px" }}>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >{getKeyByValue()}</Typography></div>
            }
          </Box>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-shopbar"
            anchorEl={anchorElShop}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElShop)}
            onClose={(e) => updateCartValue(null)}
          >
            {CART_INITIAL_STATE.listShopHash.map((shopHash) => (
              <MenuItem key={shopHash} id={shopHash} sx={{
                "&:hover": {
                  backgroundColor: "#00333e",
                  color: "white",
                },
              }} onClick={(e) => updateCartValue(shopHash)}>
                {
                  storeActiveShopHash.id === shopHash.id ?
                    <Typography textAlign="center" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: "#2f1160", fontWeight: 'bold' }}>{getSelKeyByValue(shopHash)}</Typography>
                    : <Typography textAlign="center" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{getSelKeyByValue(shopHash)}</Typography>
                }
              </MenuItem>
            ))}
          </Menu>
          {
            !isMobile && <div>
              <Tooltip title={getTips()}>
                <img alt="tips" width="30%" height="30%" src="./tips.png" />
              </Tooltip>
            </div>
          }


          &nbsp;
          {
            CART_INITIAL_STATE.pImg && <div>
              <img alt="" src={storeApiUrl.BACKEND_BASE_URL + "files/" + CART_INITIAL_STATE.pImg} width="30%" height="30%" />
            </div>
          }
          &nbsp;
          <div>
            {CART_INITIAL_STATE.userName + " "}
          </div>


          {/* <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Badge color="secondary">
                  <PersonIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {(CART_INITIAL_STATE.offlineIpSet ? offlineSettings : settings).map((setting) => (
                <MenuItem key={setting} id={setting} sx={{
                  "&:hover": {
                    backgroundColor: "#00333e",
                    color: "white",
                  },
                }} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
}
