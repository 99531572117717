import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export default function Loader(props) {

  return (
    <Box
      sx={{
        display: "flex",
        "& > * + *": {
          marginLeft: {p:2},
        },
        width: "70px",
        position: "absolute",
        left: "50%",
        top: "50%",
      }}
    >
      <CircularProgress
        color={props.color ? "secondary" : "primary"}
        size={70}
      />
    </Box>
  );
}
