import React, { useLayoutEffect, useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import {
  Grid,
  TextField,
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
  ListItemButton,
  Typography,
  Paper,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListIcon from '@mui/icons-material/List';
import InventoryIcon from '@mui/icons-material/Inventory';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Loader from "../global/loader/Loader";
import {
  GET_CONVERSIONTYPE_DATA,
  GET_CATEOGORY_DATA,
  GET_PRODUCT_DATA,
  SET_PRODUCT_DATA,
  AXIOS_TIMEOUT,
  getPreUrlAccordingToCart,
  SUCCESS,
  ItemStatus,
  CART_INITIAL_STATE, COMPONENT_PARAMS, isRoleAllowed, STOCKUPDATE, INVENTORYCREATE, CartDets, DELETEINVENTORY, FAILED, NULLDATA, SESSIONEXPIRED
} from "../../constants/constant";
import ImageUploadService from "../../service/imageUploadService";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import { useSelector } from "react-redux";
import "./Product.css";
import { snpAxios } from '../global/api';
export default function ProductEntry({ setComponentIndex, showDialogBoxWithPaper }) {
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEditProduct, setEditProduct] = useState(false);
  const [preDialog, setPreDialog] = useState(false);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [imageSelectedError, setImageSelectedError] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCategory, setSelectedcategory] = useState({
    catgId: "",
    catgName: "",
  });
  const [productSingleData, setSingleProductData] = useState({
    catgId: "",
    catgName: "",
    itemStatus: ItemStatus.ACTIVE,
    prdName: "",
    composition: [],
    compositionValue: "",
    brandName: "",
    prdId: "",
    cnvId: "",
    hsnCode: "",
    rack_no: "",
    unitName: "",
    unitCount: 1,
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 0,
    productImage: [],
    allowedDelete: false,
  });

  const [createUnit, setCreateUnit] = useState(false);

  const INITIAL_CONVERSION_SINGLE_DATA = {
    cnvId: "",
    unitName: "",
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 1,
  };


  const [conversionSingleData, setConversionSingleData] = useState(INITIAL_CONVERSION_SINGLE_DATA);
  const [conversionTypeData, setConversionTypeData] = useState([]);
  const handleConversionChange = (event, option) => {
    if (option != null) {
      // console.log("handleConversionChange", option);
      if (option.id === "cr") {
        setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
        setCreateUnit(true);
      } else {
        setCreateUnit(false);
        setConversionSingleData({
          ...conversionSingleData,
          cnvId: option.id,
          unitName: option.unitName,
          unitCount: option.unitCount,
          unitRestrictions: option.unitRestrictions,
          smallUnitName: option.smallUnitName,
          smallUnitCount: option.smallUnitCount,
        });

        // setSingleStockData({
        //   ...stockSingleData, cnvId: option.id,
        //   unitName: option.unitName,
        //   unitCount: option.unitCount,
        //   unitRestrictions: option.unitRestrictions,
        //   smallUnitName: option.smallUnitName,
        //   smallUnitCount: option.smallUnitCount,
        // });
      }
    }
  };

  const getCustomUnitDetails = (option) => {
    let data = "";
    if (option.id === 'cr')
      data = option.unitName;
    else {
      if (option.unitName) {
        if (option.unitRestrictions) {
          if (option.smallUnitCount > 0 && option.smallUnitName)
            data = "Only " + option.unitName + "(" + option.smallUnitCount + " " + option.smallUnitName + ")";
          else
            data = "Only " + option.unitName;
        } else {
          data = option.unitName + "=" + option.smallUnitCount + " " + option.smallUnitName;
        }
      }
    }
    return data;
  };
  const handleCoversionUpdate = (event) => {
    const { name, value } = event.target;
    //console.log("handleCoversionUpdate", name, value);
    if (name === 'unitRestrictions') {
      let previousCheckedValue = conversionSingleData.unitRestrictions;
      setConversionSingleData({
        ...conversionSingleData,
        unitRestrictions: !previousCheckedValue,
      });
    } else {
      setConversionSingleData({
        ...conversionSingleData,
        [name]: value,
      });
    }
  };



  const handleSelectChange = (event, catg) => {
    //console.log("handleSelectChange", catg);
    if (catg != null) {
      setSelectedcategory({ ...selectedCategory, catgId: catg.catgId, catgName: catg.catgName })
    } else {
      setSelectedcategory({ ...selectedCategory, catgId: "", catgName: "" })
    }
  };
  const handleInputChange = (event) => {
    //console.log("handleInputChange",event);
    const { name, value } = event.target;

    if (name === 'imgPath') {
      setUploadedDocument(event.target.files[0]);
      if (event.target.files[0]) {
        setImageSelectedError(false);
      } else {
        setImageSelectedError(true);
      }
    } else if (name === 'composition') {
      setSingleProductData({
        ...productSingleData,
        compositionValue: value,
      });
    } else {
      setSingleProductData({
        ...productSingleData,
        [name]: value,
      });
    }
    //console.log("setSingleProductData-> ",productSingleData);
  };

  const handleImageUpload = async () => {
    setFormSubmitted(true);
    if (!isValid()) {
      return;
    }
    let isError = false;
    // if (!uploadedDocument || uploadedDocument.size > 5242880 || !(uploadedDocument.type.includes("image"))) {    //5248256
    //   setImageSelectedError(true);
    //   isError = true;
    // }
    if (!uploadedDocument) {
      isError = true;
    }
    if (!isError) {
      setDataLoaded(false);
      setShowDialog(false);
      setFormSubmitted(false);
      let formData = new FormData();
      formData.append('fnm', CART_INITIAL_STATE.apiToken);
      formData.append("file", uploadedDocument);
      await ImageUploadService.upload(storeApiUrl.BACKEND_BASE_URL,getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
        if (response.data.folderName) {
          console.log("folderName", response.data.folderName);
          productSingleData.productImage = [];
          productSingleData.productImage.push(response.data.folderName);
          handleProductEntry(ItemStatus.ACTIVE);
        } else {
          console.log("response.data", response.data);
        }

      }
      ).catch(error => {
        handleProductEntry(ItemStatus.ACTIVE);
        console.log("handleImageUpload = > ", error);
        // window.location.href = "#/error";
      });
    } else {
      handleProductEntry(ItemStatus.ACTIVE);
    }
  };

  const getColumnsCoversion = (val) => {
    if ((val.unitCount === val.smallUnitCount) && (val.unitName && val.smallUnitName)) {
      return val.unitCount + " " + val.unitName;
    } else {
      return val.unitCount + " " + val.unitName + "(" + val.smallUnitCount + " " + val.smallUnitName + ")";
    }
  }

  const columns = [
    {
      field: "productImage",
      headerName: "Icon",
      width: 100,
      renderCell: (rowData) => (
        <div>
          <img
            alt=""
            style={{ height: "80px", width: "80px", borderRadius: "20%" }}
            src={storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage}
          ></img>
        </div>
      ),
    },
    { field: 'catgName', headerName: 'Category Name', width: 180 },
    {
      field: 'prdName', headerName: 'Item', width: 200
    },
    {
      field: 'brandName', headerName: 'Brand', width: 200
    },
    {
      field: 'conversion', headerName: 'Unit Details', width: 230,
      renderCell: (val) => {
        return getColumnsCoversion(val.row);
      }
    },
    {
      field: 'itemStatus', headerName: 'Status', width: 150,
      renderCell: (val) => {
        return (val.row.itemStatus === 1) ? 'Active' : 'Inactive';
      }
    },
    {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >
          </EditIcon>
        );
      }
    }
  ];

  const compositionColumns = [
    {
      field: "productImage",
      headerName: "Icon",
      width: 120,
      renderCell: (rowData) => (
        <div>
          <img
            alt=""
            style={{ height: "80px", width: "80px", borderRadius: "40%" }}
            src={storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
          ></img>
        </div>
      ),
    },
    { field: 'catgName', headerName: 'Category Name', width: 200 },
    {
      field: 'productName-brandName', headerName: 'Product Details', width: 230,
      renderCell: (val) => {
        return val.row.prdName + "-" + val.row.brandName;
      }
    },
    { field: 'composition', headerName: 'Composition', width: 280 },
    {
      field: 'itemStatus', headerName: 'Status', width: 150,
      renderCell: (val) => {
        return (val.row.itemStatus === 1) ? 'Active' : 'Inactive';
      }
    },
    {
      field: "edit",
      headerName: "",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >
          </EditIcon>
        );
      }
    }
  ];


  const handleCellClick = (param, event) => {
    console.log("setSingleproductData : ", event.row);
    setSelectedcategory({
      catgId: event.row.catgId,
      catgName: event.row.catgName
    });
    setConversionSingleData({
      ...setConversionSingleData,
      cnvId: event.row.cnvId,
      unitName: event.row.unitName,
      unitCount: event.row.unitCount,
      unitRestrictions: event.row.unitRestrictions,
      smallUnitName: event.row.smallUnitName,
      smallUnitCount: event.row.smallUnitCount,
    });
    setSingleProductData({
      itemStatus: event.row.itemStatus,
      prdName: event.row.prdName,
      brandName: event.row.brandName,
      prdId: event.row.prdId,
      productImage: event.row.productImage,
      catgId: event.row.catgId,
      catgName: event.row.catgName,
      cnvId: event.row.cnvId,
      hsnCode: event.row.hsnCode,
      rack_no: event.row.rack_no,
      unitName: event.row.unitName,
      unitCount: event.row.unitCount,
      unitRestrictions: event.row.unitRestrictions,
      smallUnitName: event.row.smallUnitName,
      smallUnitCount: event.row.smallUnitCount,
      composition: event.row.composition,
      allowedDelete:event.row.allowedDelete,
    })
    COMPONENT_PARAMS.prdId = event.row.prdId;
    setPreDialog(true);
    setShowDialog(true);
    setEditProduct(true);
  };


  const clearProductData = () => {
    setCreateUnit(false);
    setConversionSingleData({
      ...setConversionSingleData,
      cnvId: "",
      unitName: "",
      unitCount: 1,
      unitRestrictions: false,
      smallUnitName: "",
      smallUnitCount: 1,
    });
    setSelectedcategory({
      catgId: "",
      catgName: "",
    })
    setSingleProductData({
      itemStatus: ItemStatus.ACTIVE,
      prdName: "",
      brandName: "",
      prdId: "",
      cnvId: "",
      unitName: "",
      unitCount: 1,
      hsnCode: "",
      rack_no: "",
      unitRestrictions: false,
      smallUnitName: "",
      smallUnitCount: 0,
      productImage: [],
    })
  }

  const getConversionData = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CONVERSIONTYPE_DATA + "/" + storeActiveShopHash.cartType;
    let data = {

    };
    //   console.log("verifyCredentials => ",data);
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        if (response.data.status === SUCCESS) {
          setConversionTypeData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        console.log("error : ", error);
        //window.location.href = "#/error";
      });
  };

  const handleProductEntry = async (productStatus) => {
    setDataLoaded(false);
    setShowDialog(false);
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SET_PRODUCT_DATA;
    let filledData = {
      jcJson: {
        Admin: CART_INITIAL_STATE.userEmail,
        value: CART_INITIAL_STATE.userPassWord,
        cartType: storeActiveShopHash.cartType,
        catgId: selectedCategory.catgId,
        catgName: selectedCategory.catgName,
        composition: productSingleData.composition,
        itemStatus: parseInt(productStatus),
        prdName: productSingleData.prdName,
        brandName: productSingleData.brandName,
        hsnCode: productSingleData.hsnCode,
        rack_no: productSingleData.rack_no,
        productImage: productSingleData.productImage,
        prdId: productSingleData.prdId,
        cnvId: conversionSingleData.cnvId,
        unitName: conversionSingleData.unitName,
        smallUnitCount: parseInt(conversionSingleData.smallUnitCount),
        unitCount: parseInt(conversionSingleData.unitCount),
        smallUnitName: conversionSingleData.smallUnitName,
        unitRestrictions: conversionSingleData.unitRestrictions,
      }
    };
    //console.log("handleProductEntry => ", filledData);
    snpAxios.post(
      catgUrl,
      filledData
    ).then(response => {
      console.log("responses => ", response.data);
      if (response.data.status === SUCCESS) {
        console.log("responses => ", response.data.jcJson.prdId);
        getProductDetails();
      } else {
        setDataLoaded(true);
      }
    }).catch(error => {
      console.log("error : ", error);
      // window.location.href = "#/error";
      setDataLoaded(true);
    });
  };
  const [utils, setUtils] = useState({
    snackBarOpen: false,
    snackBarMsg: "",
  });


  
  const deleteInventory = async (prdId) => {
    handleDialogClose();
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + DELETEINVENTORY + "/" + prdId +"/" + CART_INITIAL_STATE.apiToken;
    console.log("deleteInventory => ",catgUrl);
    setDataLoaded(false);
    let data = {
    };
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        setDataLoaded(true);
        console.log("response deleteInventory = > "+response.data);
        if (response.data.status === SUCCESS) {
          setUtils({
            ...utils,
            snackBarOpen: true,
            snackBarMsg: "Successfully Deleted ",
          });
        } else {
          let msg = "";
          if (response.data.status === FAILED)
            msg = "Inventory Exist Cannot Delete!";
          else if (response.data.status === SESSIONEXPIRED)
            msg = "Session Expired! Login Again";
          else
            msg= "Failed to Delete (Error:"+response.data.status+")";
          setUtils({
            ...utils,
            snackBarOpen: true,
            snackBarMsg: msg,
          });
        }
        getProductDetails();
      }).catch((error) => {
        console.log("deleteInventory ", error);
        setDataLoaded(true);
      });
  };

  const getProductDetails = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_PRODUCT_DATA;
    let data = {
      jcJson: {
        Admin: CART_INITIAL_STATE.userEmail,
        value: CART_INITIAL_STATE.userPassWord,
        cartType: storeActiveShopHash.cartType,
      }
    };
    //  console.log("getProductDetails  => ",data);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        //    console.log("response  => ",response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setProductData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const isValid = () => {
    let isProductSingleDataValid = true;
    console.log("isValid ", createUnit,conversionSingleData);
    let isCnvId = true;
    if (!createUnit) {
      if (!conversionSingleData.cnvId) isCnvId = false;
    }
      
    if (!productSingleData.prdName || !productSingleData.brandName || !isCnvId) {
      isProductSingleDataValid = false;
    }

    return isProductSingleDataValid;
  }

  const getCartDetails = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CATEOGORY_DATA;

    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash:storeActiveShopHash.id,
      }
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        if (response.data.status === SUCCESS) {
          //setCategoryData(response.data.jcJson.value_2);
          let resp = response.data.jcJson.value_2.map((catg) => {
            // categoryData.push({
            //   label: catg.catgName,
            //   value: catg.id
            // })

            let catgName = catg.catgName
            let catgId = catg.id
            return { catgName, catgId }
          })
          //console.log("Resut : ", resp);
          setCategoryData(resp);
          //console.log(categoryData);
        }
      }).catch((error) => {
        console.log("getCartDetails ", error);
      });
  };
  useLayoutEffect(() => {
    //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
    setCreateUnit(false);
    getCartDetails();
    getConversionData();
    getProductDetails();
  }, [storeActiveShopHash,storeApiUrl]);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = productSingleData.compositionValue.trim();
      console.log("productSingleData.composition ", productSingleData.composition);
      if (value) {
        setSingleProductData({
          ...productSingleData,
          composition: productSingleData.composition === undefined ? [value] : [...productSingleData.composition, value],
          compositionValue: "",
        });
      }
    }
  };

  const handleDelete = (item) => {
    setSingleProductData({
      ...productSingleData,
      composition: productSingleData.composition.filter(i => i !== item),
    });
  };


  const renderListDialogContent = () => {
      return (
        <React.Fragment>

          <List id="parent-list-tags">
            <ListItemButton sx={{
              "&:hover": {
                backgroundColor: "#00333e",
                color: "white",
                "& .MuiListItemIcon-root": {
                  minWidth: '64px',
                  color: "white"
                }
              },
            }} onClick={() => {
              setComponentIndex(5);
              COMPONENT_PARAMS.isEditStock = 0;
            }}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Check All Live Stock Entry" />
            </ListItemButton>
          </List>
          <Divider />
          <List id="parent-list-tags">
            <ListItemButton sx={{
              "&:hover": {
                backgroundColor: "#00333e",
                color: "white",
                "& .MuiListItemIcon-root": {
                  minWidth: '64px',
                  color: "white"
                }
              },
            }} onClick={() => {
              if (isRoleAllowed(INVENTORYCREATE)) {
                setComponentIndex(5);
                COMPONENT_PARAMS.isEditStock = 1;
              } else {
                //console.log("Here");
                setShowDialog(false);
                showDialogBoxWithPaper("You Are not allowed to Add/Update Stock Details.Contact the Owner");
              }
            }}>
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary="Create New Stock Entry" />
            </ListItemButton>
          </List>
          <Divider />
          <List id="parent-list-tags">
            <ListItemButton sx={{
              "&:hover": {
                backgroundColor: "#00333e",
                color: "white",
                "& .MuiListItemIcon-root": {
                  minWidth: '64px',
                  color: "white"
                }
              },
            }} onClick={() => {
              if (isRoleAllowed(STOCKUPDATE)) {
                if (productSingleData.itemStatus === ItemStatus.ACTIVE) {
                  handleProductEntry(ItemStatus.INACTIVE);
                } else {
                  handleProductEntry(ItemStatus.ACTIVE);
                }
              } else {
                setShowDialog(false);
                showDialogBoxWithPaper("You Are not allowed to Add/Update Stock Details.Contact the Owner")
              }
            }}>
              <ListItemIcon>
                {productSingleData.itemStatus === ItemStatus.ACTIVE  ? <ToggleOffIcon /> : <ToggleOnIcon/>}
              </ListItemIcon>
              <ListItemText primary={"Make " + productSingleData.prdName + "-" + productSingleData.brandName + (productSingleData.itemStatus === ItemStatus.ACTIVE ? " Inactive" : " Active")} />
            </ListItemButton>
          </List>
          <Divider />
          <List id="parent-list-tags">
            <ListItemButton sx={{
              "&:hover": {
                backgroundColor: "#00333e",
                color: "white",
                "& .MuiListItemIcon-root": {
                  minWidth: '64px',
                  color: "white"
                }
              },
            }} onClick={() => {
              setPreDialog(false);
            }}>
              <ListItemIcon>
                <BorderColorIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Product Details" />
            </ListItemButton>
          </List>
        </React.Fragment>
      )
  };

  const renderProductEntryDialogContent = () => {
    return (
      <React.Fragment>

        <div>

          {productSingleData.productImage[0] &&
            <img
              alt=""
              style={{ height: "80px", width: "80px", borderRadius: "40%" }}
              src={storeApiUrl.BACKEND_BASE_URL + "files/" + productSingleData.productImage[0]}
            />
          }
          <Grid container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid item md={6} >
              <TextField
                accept="image/*"
                id="imgPath"
                name="imgPath"
                label=""
                multiple
                autoHighlight
                size="small"
                type="file"
                helperText="Product Image(Image should be less than 5Mb.)"
                required
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
          {imageSelectedError &&
            <Grid container sx={{
              mb: 1,
            }}>
              <Grid item md zeroMinWidth>
                <Typography variant="p" color="#EE4B2B" noWrap>Please select the Image (image format) having size below 5Mb</Typography>
                <br />
                <Typography variant="p" color="#EE4B2B" noWrap>before uploading.</Typography>
              </Grid></Grid>

          }
          <Paper elevation={0}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#f6f6f6",
              boxShadow: 1,
              borderRadius: 1,
              mb: 0.5,
              padding: 0.5
            }} ><div>
              <Grid container spacing={0.1} justifyContent="center"
                alignItems="center" sx={{
                  padding: 0.1,
                }}>
                <Grid md={12}>
                  <center>
                    <p ><u>Product Details</u> </p></center>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    disabled={isEditProduct}
                    id="prdNamessss-Name"
                    label="Enter Item Name"
                    name="prdName"
                    variant="filled"
                    size="small"
                    defaultValue={productSingleData.prdName}
                    onChange={handleInputChange}
                    fullWidth
                    error={formSubmitted && productSingleData.prdName === ""}
                    helperText={getErrorMessage('prdName')}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    id="hsnCode"
                    name="hsnCode"
                    variant="filled"
                    size="small"
                    label="Enter HSN Code"
                    value={productSingleData.hsnCode}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    id="brand-Name"
                    label="Enter Brand Name"
                    fullWidth
                    size="small"
                    name="brandName"
                    variant="filled"
                    disabled={isEditProduct}
                    defaultValue={productSingleData.brandName}
                    onChange={handleInputChange}
                    error={formSubmitted && productSingleData.brandName === ""}
                    helperText={getErrorMessage('brandName')}
                  />
                </Grid>
                <Grid item md={6} xs={12} >
                  <Autocomplete
                    id="cateogory-select"
                    options={categoryData}
                    autoHighlight
                    size="small"
                    variant="filled"
                    disabled={isEditProduct}
                    onChange={handleSelectChange}
                    fullWidth
                    getOptionLabel={(option) => option.catgName}
                    defaultValue={selectedCategory}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                      >
                        {option.catgName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a category"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    id="composition-Name"
                    label={storeActiveShopHash.cartType === CartDets.Pharma ? "Enter Composition" : "Enter SubCateogory"}
                    name="composition"
                    fullWidth
                    size="small"
                    value={productSingleData.compositionValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {productSingleData.composition && (productSingleData.composition.map(item => (
                    <div className="tag-item" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => handleDelete(item)}
                      >
                        &times;
                      </button>
                    </div>
                  )))}
                </Grid>
              </Grid></div></Paper>
          <Paper elevation={0}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#f6f6f6",
              boxShadow: 1,
              borderRadius: 1,
              mb: 0.5,
              padding: 0.5
            }} ><div>
              <Grid container columns={12} spacing={0.5}
                justifyContent="center"
                alignItems="center" sx={{
                  padding: 0.2,
                }}>
                <Grid md={12}>
                  <center>
                    <p ><u>Unit & Location</u> </p></center>
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    id="rack_no"
                    name="rack_no"
                    variant="filled"
                    size="small"
                    label="Enter Rack No(Location)"
                    value={productSingleData.rack_no}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  < Autocomplete
                    id="cnv-select"
                    size="small"
                    disabled={isEditProduct}
                    options={conversionTypeData}
                    onChange={handleConversionChange}
                    fullWidth
                    getOptionLabel={(option) => getCustomUnitDetails(option)}
                    defaultValue={conversionSingleData}
                    renderOption={(props, option) => (
                      <Box
                        component="li"

                        {...props}
                      >
                        {getCustomUnitDetails(option)}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Custom Unit Type"
                        error={formSubmitted && !productSingleData.cnvId}
                        helperText={getErrorMessage('cnv-select')}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>



                {
                  createUnit &&
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="unitName"
                      label="Enter Unit Name"
                      name="unitName"
                      variant="filled"
                      size="small"
                      defaultValue={conversionSingleData.unitName}
                      onChange={handleCoversionUpdate}
                    />
                  </Grid>
                }
                {
                  createUnit &&
                  <Grid item xs={12} md={6}>
                    <FormControlLabel control={<Checkbox checked={conversionSingleData.unitRestrictions}
                      onChange={handleCoversionUpdate} id="unitRestrictions" />} name="unitRestrictions" label="Unit Restrictions" />
                  </Grid>
                }
                {
                  createUnit &&
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="smallUnitCount"
                      variant="filled"
                      size="small"
                      label="Enter SmallUnit Count"
                      name="smallUnitCount"
                      defaultValue={conversionSingleData.smallUnitCount}
                      onChange={handleCoversionUpdate}
                    />
                  </Grid>
                }

                {
                  createUnit &&
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      variant="filled"
                      size="small"
                      id="smallUnitName"
                      label="Enter SmallUnit Name"
                      name="smallUnitName"
                      defaultValue={conversionSingleData.smallUnitName}
                      onChange={handleCoversionUpdate}
                    />
                  </Grid>
                }
              </Grid></div></Paper>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Enter Item Status</FormLabel>
              <RadioGroup aria-label="itemStatus" name="itemStatus" value={productSingleData.itemStatus.toString()} onChange={handleInputChange} row={true}>
                <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />

              </RadioGroup>
            </FormControl>
          </Grid>
          {
            productSingleData.allowedDelete && <Grid item md={4} xs={6}>
              <Button variant="contained" color="bRed" onClick={() => deleteInventory(productSingleData.prdId)}>
                Delete Product
              </Button>
            </Grid>
          }

        </div>
      </React.Fragment>
    )
  };

  const renderDialogContent = () => {
    return (preDialog ? renderListDialogContent() : renderProductEntryDialogContent());
  }

  const renderDialogAction = () => {
    return (
      !preDialog ? 
        <React.Fragment>
            <Button variant="contained" color="primary" onClick={handleImageUpload}>
              {isEditProduct ? "Update" : "Submit"}
            </Button>
          
          <Button variant="contained" color="primary" onClick={handleDialogClose}>
            Cancel
          </Button>
        </React.Fragment>
        : null
    );
  }

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;
      
    } else if (!productSingleData[field]) {
      return 'Field is required';
    // } else if (!selectedCategory.catgId) {
    //   return 'Field is required';
    }
  }

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setShowDialog(false);
    COMPONENT_PARAMS.isEditStock = 0;
    COMPONENT_PARAMS.prdId = "";
    clearProductData();
    setEditProduct(false);
    setPreDialog(false);
    setFormSubmitted(false);
  };

const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUtils({
      ...utils,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };



  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: 10 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ float: "right" }}
              onClick={(event) => {
                if (isRoleAllowed(INVENTORYCREATE)) {
                  setShowDialog(true);
                } else {
                  showDialogBoxWithPaper("You Are not allowed to Add Product Stock.Contact the Owner");
                }
              }}>
              Add New Product
            </Button>
            <Snackbar
              open={utils.snackBarOpen}
              autoHideDuration={3000}
              message={utils.snackBarMsg}
              onClose={handleSnackClose}
            />
          </div>

          {isDataLoaded ? (
            <CustomDataGrid
              getRowId={row => row.prdId}
              rows={productData}
              columns={storeActiveShopHash.cartType === CartDets.Pharma ? compositionColumns : columns}
              pageSize={7}
              rowsPerPageOptions={[5]}
              autoHeight
            />
          ) : (
            <Loader />
          )}
        </Grid>
      </Grid>
      < DialogBox
        showDialog={showDialog}
        title={preDialog ? "" : isEditProduct ? "View Product" : "Add New Product"}
        onClose={handleDialogClose}
        dialogContent={renderDialogContent()}
        dialogActions={renderDialogAction()}
      ></DialogBox>
    </React.Fragment>
  );
}