import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Box, Autocomplete, Paper, FormControl, TableBody, Checkbox } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../global/loader/Loader";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    AccountingPaidMode,
    GET_ACCOUNTING_DATA,
    GET_ACCOUNTING_DATA_ID,
    SET_ACCOUNTING_DATA,
    SUCCESS,
    CART_INITIAL_STATE,
    DEBIT,
    CREDIT,
    BillStatus, GETSTOCKFRACCOUNTING, isRoleAllowed, ACCCREATE, ACCUPDATE, isEmpty, ADMINRIGHTS, GETACCEMPDETAILS, isFeatureAllowed, SUPMGMT, GETSUPPLIERACCOUNTING, ONLINEPAY, CASHPAY, isNumberEmpty, HOTELSALESCONFIG, HOTELMGMT, getPreUrlAccordingToCart, GETSINGLECONFIGS, INVALIDBILL, HOTELSALES, TRANSFER, RECEIVE, GET_BALANCE_DATA, MAINACCOUNTING, INITIAL_PAGEMODEL, PAGINATIONNFILTER, MULTIACCVIEW
} from "../../constants/constant";
import './../chart/Chart.css';

import DialogBox from "../global/DialogBox";
import CustomDataGrid from "../global/CustomDataGrid";
import {
    Button,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio, Typography
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import CustomFilterInput from "../global/CustomFilterInput";
import { GridFilterPanel } from "@mui/x-data-grid";
import { snpAxios } from "../global/api";
import axios from "axios";



export default function Accounting({ showDialogBoxWithPaper }) {

    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [accountData, setAccountData] = useState([]);
    const [mAccType, setAccType] = useState(MAINACCOUNTING);
    const [utils, setUtils] = useState({
        snackBarOpen: false,
        snackBarMsg: "",
        showDialog: false,
        isEdit: false,
        isAmountChangeAllow: false,
        clearDues: false,
        submitAllowed: false,
    });
    const [isLoading,setIsLoading] = useState(false);
    const [isOnlinePayEnabled, setOnlinePayEnb] = useState(false);
    const [isCashPayEnabled, setCashPayEnb] = useState(true);
    const [supplierEdit, setSupplierEdit] = useState(false);
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGEMODEL);
    const [paginationWay,setPaginationWay] = useState("client");

    const INITIAL_ACC_DATA = {
        id: "",
        amount: 0.0,
        userName: "",
        drCr: DEBIT,
        message: "",
        mode: AccountingPaidMode.MISC,
        paidStatus: BillStatus.PAID,
        partialAmt: 0.0,
        parentRef: "",
        childRef: "",
        supId: "",
        supName: "",
        stockRefNo: "",
        details: "",
        billNo: "",
        createdAt: "",
        onlinePayThrough: "",
        cashPayThrough: CASHPAY,
        onlinePay: 0.0,
        cashPay: 0.0,
        entryBy: "",
        duesStr: "",
        dues: 0,
        payCount: "",
        refId: "",
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        isGstAllowed: true,
        hotelBookingNo:"",
        accountType:MAINACCOUNTING,
    }
    const [accSingleData, setSingleAccData] = useState(INITIAL_ACC_DATA);

    const [supplierData, setSupplierData] = useState([]);
    const [refManagementData, setRefmgmtData] = useState({
        id: "",
        details: "",
    });

    const [stockData, setStockData] = useState([]);
    const [empData, setEmpData] = useState([]);


    const [formSubmitted, setFormSubmitted] = useState(false);
    const [listAcc, setListAcc] = useState([]);
    const [todayMinus10,setTodayMinus10] = useState('?');
    const [dayBookDesigns, setDayBookColVals] = useState([]);
    const [userPieData, setUserPieData] = useState([]);
    const [userPieDataToday, setUserPieDataToday] = useState([]);
    const [showPie, setShowPie] = useState(false);
    const [showData, setShowData] = useState(true);

    const [isAdmin, setAdmin] = useState(true);
    const [totalBal, setTotalBalance] = useState(0.0);
    const [totalCashBalance, setTotatCashBal] = useState(0.0);
    const [totalOnlineBalance, setTotalOnlineBal] = useState(0.0);
    const [todayCashBalance, setTodayCashBal] = useState(0.0);
    const [todayOnlineBalance, setTodayOnlineBal] = useState(0.0);
    const [hotelSales,setHotelSales] = useState(false);

    const handleAutoCompleteChange = (event, option) => {
        if (option != null) {
            //console.log("handleStockChange", option);
            //setSupplierEdit(true);
            setRefmgmtData({
                ...refManagementData,
                id: option.id,
                details: option.details
            });
            setSingleAccData({
                ...accSingleData, refId: option.id,
            });

        }
    };

    const handleAccTypeChange = (event, option) => {
        if (option != null) {
            //console.log("handleAccTypeChange", option);
            getBalanceDetails(option);
        }
    };



    const getSupplierDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETSUPPLIERACCOUNTING;
        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getSupplierDetails  => ",data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setSupplierData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getStockDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETSTOCKFRACCOUNTING;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
            }
        };
        //  console.log("getStockDetails  => ",data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setStockData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === HOTELSALESCONFIG)
                        setHotelSales(response.data === 'Yes');
                    else if (mConfigName === PAGINATIONNFILTER) {
                        setPaginationWay(response.data);
                        handleFilterQuery(paginationModel.page,paginationModel.pageSize,null,response.data);
                    }
                }
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                if (mConfigName === PAGINATIONNFILTER) {
                    handleFilterQuery(paginationModel.page,paginationModel.pageSize,null,paginationWay);
                }
            });
    };


    const getAllEmpDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETACCEMPDETAILS;
        let data = {
            jcJson: {
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getStockDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {

                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setEmpData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };
    const handleOnlineSelectChange = (event, payMethod) => {
        //printLog("handleOnlineSelectChange", payMethod);
        if (payMethod != null) {
            setSingleAccData({
                ...accSingleData,
                onlinePayThrough: payMethod,
            });
        } else {
            setSingleAccData({
                ...accSingleData,
                onlinePayThrough: "",
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange", name, value);
        if (name === 'isGstAllowed') {
            let val = !accSingleData.isGstAllowed;
            setSingleAccData({
                ...accSingleData, isGstAllowed: val,
                cgstAmt: (val ? accSingleData.cgstAmt : 0.0),
                sgstAmt: (val ? accSingleData.sgstAmt : 0.0),
            });
        } else if (name === 'mode' && parseInt(value) === AccountingPaidMode.CLEAR_CASH_BALANCE) {
            //console.log("name === 'mode'")
            setSingleAccData({
                ...accSingleData, mode: value,
                drCr: DEBIT,
                paidStatus: BillStatus.PAID,
                partialAmt: 0.0,
            });
        } else if (name === 'mode' && parseInt(value) === AccountingPaidMode.MISC) {
            //console.log("name === 'mode'")
            setSingleAccData({
                ...accSingleData, mode: value,
                drCr: DEBIT,
                paidStatus: BillStatus.PAID,
                partialAmt: 0.0,
            });
        } else if (name === 'mode' && parseInt(value) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK) {
            //console.log("name === 'mode'")
            setSingleAccData({
                ...accSingleData, mode: value,
                drCr: DEBIT,
                paidStatus: BillStatus.PAID,
                partialAmt: 0.0,
            });
        } else if (name === 'onlinePayEnb') {
            let val = !isOnlinePayEnabled;
            //console.log("onlinePayEnb",CART_INITIAL_STATE.onlinePayMethod[0])
            setOnlinePayEnb(val);
            if (val) {
                if (accSingleData.drCr === DEBIT) {
                    if (isCashPayEnabled) {
                        let cash = accSingleData.amount / 2;
                        let online = accSingleData.amount / 2;
                        if (cash > totalCashBalance) {
                            cash = totalCashBalance;
                            online = accSingleData.amount - totalCashBalance;
                        } else if (online > totalOnlineBalance) {
                            online = totalOnlineBalance;
                            cash = accSingleData.amount - totalOnlineBalance;
                        }
                        setSingleAccData({
                            ...accSingleData,
                            onlinePayThrough: CART_INITIAL_STATE.onlinePayMethod[0],
                            onlinePay: online,
                            cashPay:cash,
                        })
                    } else {
                        setSingleAccData({
                            ...accSingleData,
                            onlinePayThrough: CART_INITIAL_STATE.onlinePayMethod[0],
                            amount: accSingleData.amount,
                            onlinePay: accSingleData.amount,
                        });
                    }
                } else {
                    if (isCashPayEnabled) {
                        let cash = accSingleData.amount / 2;
                        let online = accSingleData.amount / 2;
                        setSingleAccData({
                            ...accSingleData,
                            onlinePayThrough: CART_INITIAL_STATE.onlinePayMethod[0],
                            onlinePay: online,
                            cashPay:cash,
                        })
                    } else {
                        setSingleAccData({
                            ...accSingleData,
                            onlinePayThrough: CART_INITIAL_STATE.onlinePayMethod[0],
                            amount: accSingleData.amount,
                            onlinePay: accSingleData.amount,
                        });
                    }
                }
            } else {
                setSingleAccData({
                    ...accSingleData,
                    onlinePayThrough: "",
                    onlinePay: 0.0,
                    cashPay: 0.0,
                    amount:0.0,
                })
            }
           
        } else if (name === 'onlinePay') {
            if (!isNumberEmpty(value)) {
                let online = Number(value);
                if (accSingleData.drCr === DEBIT) {
                    if (isCashPayEnabled) {
                        if (online <= totalOnlineBalance) {
                            let cash = accSingleData.amount - online;
                            if (online <= totalCashBalance) {
                                setSingleAccData({
                                    ...accSingleData,
                                    onlinePay: online,
                                    cashPay: cash,
                                })
                            } else {
                                setUtils({
                                    ...utils,
                                    snackBarOpen: true,
                                    snackBarMsg: "Exceed Cash Balance",
                                })
                            }
                        } else {
                            setUtils({
                                ...utils,
                                snackBarOpen: true,
                                snackBarMsg: "Exceed Online Balance",
                            })
                        }
                    }
                } else {
                    if (isCashPayEnabled) {
                        let cash = accSingleData.amount - online;
                        setSingleAccData({
                            ...accSingleData,
                            cashPay: cash,
                            onlinePay: online,
                        });
                    }
                }
            } else {
                if (accSingleData.drCr === DEBIT) {
                    if (isCashPayEnabled) {
                        let cash = accSingleData.amount;
                        if (cash <= totalCashBalance) {
                            setSingleAccData({
                                ...accSingleData,
                                onlinePay: 0,
                                cashPay: cash,
                            })
                        } else {
                            setUtils({
                                ...utils,
                                snackBarOpen: true,
                                snackBarMsg: "Exceed Cash Balance",
                            })
                        }
                    }
                } else {
                    if (isCashPayEnabled) {
                        let cash = accSingleData.amount;
                        setSingleAccData({
                            ...accSingleData,
                            cashPay: cash,
                            onlinePay: 0,
                        });
                    }
                }
            }
            
        }else if (name === 'cashPayEnb') {
            let val = !isCashPayEnabled;
            setCashPayEnb(val);
            if (val) {
                if (accSingleData.drCr === DEBIT) {
                    if (isOnlinePayEnabled) {
                        let cash = accSingleData.amount / 2;
                        let online = accSingleData.amount / 2;
                        if (cash > totalCashBalance) {
                            cash = totalCashBalance;
                            online = accSingleData.amount - totalCashBalance;
                        } else if (online > totalOnlineBalance) {
                            online = totalOnlineBalance;
                            cash = accSingleData.amount - totalOnlineBalance;
                        }
                        setSingleAccData({
                            ...accSingleData,
                            cashPayThrough: CASHPAY,
                            onlinePay: online,
                            cashPay: cash,
                        })
                    } else {
                        setSingleAccData({
                            ...accSingleData,
                            cashPayThrough: CASHPAY,
                            amount: accSingleData.amount,
                            cashPay: accSingleData.amount,
                        });
                    }
                } else {
                    if (isOnlinePayEnabled) {
                        let cash = accSingleData.amount / 2;
                        let online = accSingleData.amount / 2;
                        setSingleAccData({
                            ...accSingleData,
                            cashPayThrough: CASHPAY,
                            onlinePay: online,
                            cashPay: cash,
                        })
                    } else {
                        setSingleAccData({
                            ...accSingleData,
                            cashPayThrough: CASHPAY,
                            amount: accSingleData.amount,
                            cashPay: accSingleData.amount,
                        });
                    }
                }
            } else {
                setSingleAccData({
                    ...accSingleData,
                    cashPayThrough: "",
                    onlinePay: 0.0,
                    cashPay: 0.0,
                    amount: 0.0,
                })
            }
        } else if (name === 'cashPay') {
            if (!isNumberEmpty(value)) {
                let cash = Number(value);
                if (accSingleData.drCr === DEBIT) {
                    if (isOnlinePayEnabled) {
                        if (cash <= totalCashBalance) {
                            let online = accSingleData.amount - cash;
                            if (online <= totalOnlineBalance) {
                                setSingleAccData({
                                    ...accSingleData,
                                    onlinePay: online,
                                    cashPay: cash,
                                })
                            } else {
                                setUtils({
                                    ...utils,
                                    snackBarOpen: true,
                                    snackBarMsg: "Exceed Online Balance",
                                })
                            }
                            
                        } else {
                            setUtils({
                                ...utils,
                                snackBarOpen: true,
                                snackBarMsg: "Exceed Cash Balance",
                            })
                        }
                        
                    }
                } else {
                    if (isOnlinePayEnabled) {
                        let online = accSingleData.amount - cash;
                        setSingleAccData({
                            ...accSingleData,
                            cashPay: cash,
                            onlinePay: online,
                        });
                    }
                }
            } else {
                if (accSingleData.drCr === DEBIT) {
                    if (isOnlinePayEnabled) {
                        let online = accSingleData.amount;
                        if (online <= totalOnlineBalance) {
                            setSingleAccData({
                                ...accSingleData,
                                onlinePay: online,
                                cashPay: 0,
                            })
                        } else {
                            setUtils({
                                ...utils,
                                snackBarOpen: true,
                                snackBarMsg: "Exceed Online Balance",
                            })
                        }
                    }
                } else {
                    if (isOnlinePayEnabled) {
                        let online = accSingleData.amount;
                        setSingleAccData({
                            ...accSingleData,
                            cashPay: 0,
                            onlinePay: online,
                        });
                    }
                }
            }
            
        } else if (name === 'amount') {
            if (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE) {
                if (!isNumberEmpty(value)) {
                    if (Number(value) <= totalCashBalance) {
                        setSingleAccData({
                            ...accSingleData,
                            amount: value,
                            cashPay: value,
                        });
                    } else {
                        setUtils({
                            ...utils,
                            snackBarOpen: true,
                            snackBarMsg: "Exceed Cash Amount i.e "+totalCashBalance,
                        })
                    }
                } else {
                    setSingleAccData({
                        ...accSingleData,
                        amount: value,
                        cashPay: value,
                    });
                }
            } else if (parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK) {
                //console.log("handleInputChange", name, value ,totalCashBalance);
                if (!isNumberEmpty(value)) {
                    if (Number(value) <= totalCashBalance) {
                        setSingleAccData({
                            ...accSingleData,
                            amount: value,
                            cashPay: value,
                        });
                    } else {
                        setUtils({
                            ...utils,
                            snackBarOpen: true,
                            snackBarMsg: "Exceed Cash Amount",
                        })
                    }
                } else {
                    setSingleAccData({
                        ...accSingleData,
                        amount: value,
                        cashPay: value,
                    });
                }
            } else {
                if (accSingleData.drCr === DEBIT) {
                    //console.log("handleInputChange", name, value ,totalBal);
                    if (!isNumberEmpty(value)) {
                        let tBal = Number(value);
                        if (isCashPayEnabled && isOnlinePayEnabled) {
                            if (tBal <= totalBal) {
                                let cash = tBal / 2;
                                let online = tBal / 2;
                                if (cash > totalCashBalance) {
                                    cash = totalCashBalance;
                                    online = tBal - totalCashBalance;
                                } else if (online > totalOnlineBalance) {
                                    online = totalOnlineBalance;
                                    cash = tBal - totalOnlineBalance;
                                }
                                setSingleAccData({
                                    ...accSingleData,
                                    amount: tBal,
                                    cashPay: cash,
                                    onlinePay: online,
                                });
                            } else {
                                setUtils({
                                    ...utils,
                                    snackBarOpen: true,
                                    snackBarMsg: "Exceed Total Balance",
                                })
                            }
                        } else {
                            if (isCashPayEnabled) {
                                if (tBal <= totalCashBalance) {
                                    setSingleAccData({
                                        ...accSingleData,
                                        amount: tBal,
                                        cashPay: tBal,
                                    });
                                } else {
                                    setUtils({
                                        ...utils,
                                        snackBarOpen: true,
                                        snackBarMsg: "Exceed Cash Balance",
                                    })
                                }
                            } else if (isOnlinePayEnabled) {
                                if (tBal <= totalOnlineBalance) {
                                    setSingleAccData({
                                        ...accSingleData,
                                        amount: tBal,
                                        onlinePay: tBal,
                                    });
                                } else {
                                    setUtils({
                                        ...utils,
                                        snackBarOpen: true,
                                        snackBarMsg: "Exceed Online Balance",
                                    })
                                }
                            } else {
                                setUtils({
                                    ...utils,
                                    snackBarOpen: true,
                                    snackBarMsg: "Please Select Pay Method",
                                })
                            }
                        }
                    }
                } else {
                    let tBal = Number(value);
                    if (isCashPayEnabled && isOnlinePayEnabled) {
                        let cash = tBal / 2;
                        let online = tBal / 2;
                        setSingleAccData({
                            ...accSingleData,
                            amount: tBal,
                            cashPay: cash,
                            onlinePay: online,
                        });
                    } else {
                        if (isCashPayEnabled) {
                            setSingleAccData({
                                ...accSingleData,
                                amount: tBal,
                                cashPay: tBal,
                            });
                        } else if (isOnlinePayEnabled) {
                            setSingleAccData({
                                ...accSingleData,
                                amount: tBal,
                                onlinePay: tBal,
                            });
                        } else {
                            setUtils({
                                ...utils,
                                snackBarOpen: true,
                                snackBarMsg: "Please Select Pay Method",
                            })
                        }
                    }
                }
            }
    } else {
        setSingleAccData({
            ...accSingleData,
            [name]: value,
        });
}

    };

const columns = [
    { field: "accountType", headerName: "Bal.Type", width: 120,filterable: true },
    { field: "createdAt", headerName: "Created At", width: 160 ,filterable: true},
    { field: "drCr", headerName: "Denom.", width: 90 ,filterable: true},
    { field: "amount", headerName: "Amt", width: 90 ,filterable: true},
    {
        field: "duesStr", headerName: "Dues", width: 150
        ,filterable: true},
    { field: "entryBy", headerName: "Entry By", width: 150 ,filterable: true},
    { field: "message", headerName: "Msg", width: 200 ,filterable: true},
    {
        field: "view",
        headerName: "View",
        width: 80,
        filterable: false,
        renderCell: (cellValues) => {
            return (
                <EditIcon
                    sx={{
                        color: "black"
                    }}
                    onClick={(event) => {
                        if (isRoleAllowed(ACCUPDATE)) {
                            handleCellClick(event, cellValues);
                        } else {
                            showDialogBoxWithPaper("You Are not allowed to Update Entry.Contact the Owner");
                        }

                    }}
                ></EditIcon>
            );
        },
    },
];

const handleCellClick = (param, event) => {
    //console.log("handleCellClick , ", event.row);
    setRefmgmtData({
        ...refManagementData,
        id: event.row.refId,
        details: event.row.details,
    });
    setSingleAccData({
        ...accSingleData, ...event.row,
    });

    setCashPayEnb(!isEmpty(event.row.cashPayThrough));
    setOnlinePayEnb(!isEmpty(event.row.onlinePayThrough));

    //console.log("setSingleCustomerData : ",customerSingleData);
    setUtils({
        ...utils,
        isEdit: true,
        clearDues: ((event.row.drCr === DEBIT) && (event.row.childRef === '') && ((parseInt(event.row.paidStatus) === BillStatus.NOTPAID) || (parseInt(event.row.paidStatus) === BillStatus.PARTIALLYPAID))),
        showDialog: true,
    });
};

const handleClearDues = () => {
    setSingleAccData({
        ...accSingleData,
        amount: parseFloat(accSingleData.amount) - parseFloat(accSingleData.partialAmt),
        id: "",
        userName: "",
        drCr: DEBIT,
        message: "",
        mode: accSingleData.mode,
        paidStatus: BillStatus.PAID,
        partialAmt: 0.0,
        parentRef: accSingleData.id,
        childRef: "",
        refId: accSingleData.refId,
        supId: accSingleData.supId,
        supName: accSingleData.supName,
        createdAt: "",
    });
    setUtils({
        ...utils,
        isEdit: true,
        clearDues: true,
        submitAllowed: true,
        isAmountChangeAllow: true,
    });
};



const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setUtils({
        ...utils,
        snackBarOpen: false,
        snackBarMsg: "",
    });
};
const handleSearchByRef = async (refId) => {
    setUtils({
        ...utils,
        isEdit: false,
        showDialog: false,
        snackBarOpen: false,
        snackBarMsg: "",
        isAmountChangeAllow: false,
        clearDues: false,
        submitAllowed: false,
    });
    let searchByRef =
        storeApiUrl.BACKEND_BASE_URL + GET_ACCOUNTING_DATA_ID;
    let data = {
        jcJson: {
            Admin: CART_INITIAL_STATE.userEmail,
            value: CART_INITIAL_STATE.userPassWord,
            ref: refId
        },
    };
    //console.log("handleSearchByRef => ",searchByRef +"  "+refId);
    snpAxios
        .post(searchByRef, data)
        .then((response) => {

            //console.log("handleSearchByRef => ",response.data.jcJson);
            if (response.data.status === SUCCESS) {

                setSingleAccData({
                    ...accSingleData, ...response.data.jcJson,
                });
                setUtils({
                    ...utils,
                    isEdit: true,
                    clearDues: ((response.data.jcJson.childRef === '') && ((parseInt(response.data.jcJson.paidStatus) === BillStatus.NOTPAID) || (parseInt(response.data.jcJson.paidStatus) === BillStatus.PARTIALLYPAID))),
                    showDialog: true,
                    isAmountChangeAllow: false,
                    submitAllowed: false,
                });
            }
        })
        .catch((error) => {
           
            console.log("Error : ", error);
            //window.location.href = "#/error";
        });
};

const getBalanceDetails = async (accType) => {
    let catgUrl =
        storeApiUrl.BACKEND_BASE_URL + GET_BALANCE_DATA;
    let data = {
        jcJson: {
            apiToken: CART_INITIAL_STATE.apiToken,
            shopHash: storeActiveShopHash.id,
            accountType:accType,
        }
    };
    setIsLoading(true);
    if (!isEmpty(accType))
        setAccType(accType);
    snpAxios
        .post(catgUrl, data)
        .then((response) => {
            setIsLoading(false);
            //console.log("getBalanceDetails => ", response.data.jcJson);
            if (response.data.status === SUCCESS) {
                setAdmin(response.data.jcJson.admin);
                setTotalBalance(response.data.jcJson.totalBal);
                setTotalOnlineBal(response.data.jcJson.totalOnlineBalance);
                setTotatCashBal(response.data.jcJson.totalCashBalance);
                setTodayCashBal(response.data.jcJson.todaysCashBalance);
                setTodayOnlineBal(response.data.jcJson.todaysOnlineBalance);
                setDayBookColVals(response.data.jcJson.dayBook);
                if (isRoleAllowed(MULTIACCVIEW))
                    setListAcc(response.data.jcJson.listAcc);
                //backgroundColor: ["#4d908e", "#122121", "#2d5352" , "#f9844a", "#df4f07", "#990000","#4d0000"],
                setUserPieData({
                    ...userPieData,
                    labels: response.data.jcJson.dayBookDesigns.map(data => data.tye),
                    datasets: [{
                        label: "Accounting",
                        data: response.data.jcJson.dayBookDesigns.map(data => data.total),
                        backgroundColor: ["#4d0000", "#4d908e", "#122121", "#f9844a"],
                        hoverOffset: 4
                    }]
                });
                setUserPieDataToday({
                    ...userPieDataToday,
                    labels: response.data.jcJson.dayBookDesignsToday.map(data => data.tye),
                    datasets: [{
                        label: "Accounting",
                        data: response.data.jcJson.dayBookDesignsToday.map(data => data.total),
                        backgroundColor: ["#990000", "#2d5352", "#df4f07"],
                        hoverOffset: 4
                    }]
                });
                setShowPie(true);

            } 
        })
        .catch((error) => {
            setIsLoading(false);
            console.log("Error : ", error);
            //window.location.href = "#/error";
        });
};


const getAccountingData = async (mPage,mPageSize,data,pWay) => {
    let catgUrl =
        storeApiUrl.BACKEND_BASE_URL + GET_ACCOUNTING_DATA;
    if (pWay === "server") 
        catgUrl = catgUrl +"/"+mPage+"/"+mPageSize;
    setSingleAccData(INITIAL_ACC_DATA);
    setIsLoading(true);
    //console.log("verifyCredentials => ",catgUrl);
    snpAxios
        .post(catgUrl, data)
        .then((response) => {
            
            //console.log("getAccountingData => ", response.data.jcJson);
            if (response.data.status === SUCCESS) {
                setAccountData(response.data.jcJson.value_2);
                setTodayMinus10(response.data.jcJson.todaysMinus10);
                setPaginationModel({
                    ...paginationModel,
                    page:mPage,
                    pageSize:mPageSize,
                    rowCount:response.data.jcJson.totalRecords,
                });
                
            } 
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            console.log("Error : ", error);
            //window.location.href = "#/error";
        });
};


const handleSubmitAccountEntry = async () => {
    setFormSubmitted(true);
    if (!isValid()) {
        return;
    }
    let accUrl =
        storeApiUrl.BACKEND_BASE_URL + SET_ACCOUNTING_DATA;
    let filledData = {
        jcJson: {
            apiToken: CART_INITIAL_STATE.apiToken,
            shopHash: storeActiveShopHash.id,
            amount: parseFloat(accSingleData.amount),
            id: accSingleData.id,
            refId: accSingleData.refId,
            drCr: accSingleData.drCr,
            parentRef: accSingleData.parentRef,
            childRef: accSingleData.childRef,
            message: accSingleData.message,
            mode: parseInt(accSingleData.mode),
            paidStatus: parseInt(accSingleData.paidStatus),
            partialAmt: parseFloat(accSingleData.partialAmt),
            isGstAllowed: accSingleData.isGstAllowed,
            cgstAmt: accSingleData.cgstAmt,
            sgstAmt: accSingleData.sgstAmt,
            cashPay: isOnlinePayEnabled ? accSingleData.cashPay : isCashPayEnabled ? parseFloat(accSingleData.amount) : 0.0,
            onlinePay: accSingleData.onlinePay,
            cashPayThrough: accSingleData.cashPayThrough,
            onlinePayThrough: accSingleData.onlinePayThrough,
            hotelBookingNo:accSingleData.hotelBookingNo,
            accountType:accSingleData.accountType,
        },
    };
    // console.log("handleSubmitAccountEntry => ",filledData);
    clearData();
    setIsLoading(true);
    setUtils({
        ...utils,
        showDialog: false,
        isAmountChangeAllow: false,
        isEdit: false,
        snackBarOpen: false,
        snackBarMsg: "",
        clearDues: false,
        submitAllowed: false,
    });
    snpAxios.post(accUrl, filledData)
        .then((response) => {
            //console.log("responses => ", response.data);

            

            if (response.data.status === SUCCESS) {
                setIsLoading(false);
                setUtils({
                    ...utils,
                    showDialog: false,
                    isAmountChangeAllow: false,
                    isEdit: false,
                    snackBarOpen: false,
                    snackBarMsg: "",
                    clearDues: false,
                    submitAllowed: false,
                });
                getBalanceDetails(MAINACCOUNTING);
                handleFilterQuery(paginationModel.page,paginationModel.pageSize,null,paginationWay);
            } else if (response.data.status === INVALIDBILL) {
                setIsLoading(false);
                setUtils({
                    ...utils,
                    showDialog: false,
                    isAmountChangeAllow: false,
                    isEdit: false,
                    snackBarOpen: true,
                    snackBarMsg: "Invalid Booking Details ,Please Enter correct booking no again",
                    clearDues: false,
                    submitAllowed: false,
                });
            }
        })
        .catch((error) => {
            setUtils({
                ...utils,
                showDialog: false,
                isAmountChangeAllow: false,
                isEdit: false,
                snackBarOpen: false,
                snackBarMsg: "",
                clearDues: false,
                submitAllowed: false,
            });
            setIsLoading(false);
            console.log("error : ", error);
            setFormSubmitted(false);

            //window.location.href = "#/error";
        });
};

const firstTimeCall = () => {
    //getAllEmpDetails();
    getSingleConfigs(PAGINATIONNFILTER);
    if (isFeatureAllowed(HOTELMGMT)) 
        getSingleConfigs(HOTELSALESCONFIG);
    if (isFeatureAllowed(SUPMGMT)) {
        getStockDetails();
        getSupplierDetails();
    }
    getBalanceDetails(MAINACCOUNTING);
    
};

useLayoutEffect(() => {
    firstTimeCall();
}, [storeCartType, storeActiveShopHash,storeApiUrl]);

const getDefDebitCreditDisableStatus = (accSingleData) => {
    if (utils.isEdit)
        return true;
    if (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE
        || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)
        return true
    return false;
};


const renderDialogContent = () => {
    return (
        <React.Fragment>
            <div>
                <Grid container spacing={1} justifyContent="center"
                    alignItems="center" sx={{
                        padding: 0, margin: 0,
                    }}>
                    {
                        !isEmpty(accSingleData.payCount) &&
                        <Grid item md={6} sm={6} xs={12} lg={6}>
                            <p>{accSingleData.payCount}</p>
                            {/* {
                                    !isEmpty(accSingleData.parentRef) &&
                                    <SearchIcon onClick={() => handleSearchByRef(accSingleData.parentRef)}
                                        style={{ justifyContent: "center", alignContent: "center", }} />
                                } */}

                        </Grid>
                    }
                    {

                        (isRoleAllowed(MULTIACCVIEW) && listAcc.length > 1) && 
                        <Grid item md={6} sm={12} xs={12} lg={6}>
                        <Autocomplete
                            id="acc-select"
                            options={listAcc}
                            autoHighlight
                            variant="filled"
                            onChange={(e,item) => {
                                let val = item;
                                if (item ===null) 
                                    val = MAINACCOUNTING;
                                setSingleAccData({
                                    ...accSingleData,accountType: val,
                                });
                            }}
                            size="small"
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionLabel={(option) => option}
                            defaultValue={accSingleData.accountType}
                            value={accSingleData.accountType}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Account Type"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        /> </Grid>
                    }
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <FormLabel component="legend"
                            sx={{
                                fontSize: "large",
                            }}
                        >Select Mode of Accounting</FormLabel>
                        <RadioGroup row aria-label="mode"
                            size="small"
                            name="mode" value={accSingleData.mode.toString()} onChange={handleInputChange} >
                            <FormControlLabel disabled={utils.isEdit} value={AccountingPaidMode.MISC} control={<Radio />} variant="filled"
                                size="small"
                                label="Miscellaneous Entry"
                            />
                            {
                                isFeatureAllowed(SUPMGMT) && <FormControlLabel disabled={utils.isEdit} value={AccountingPaidMode.VIASUPPLIER} control={<Radio />} variant="filled"
                                    size="small"
                                    label="Accounting Related to Supplier" />
                            }
                            {
                                isFeatureAllowed(SUPMGMT) &&
                                <FormControlLabel disabled={utils.isEdit} value={AccountingPaidMode.VIASTOCK} control={<Radio />} variant="filled"
                                    size="small"
                                    label="Accounting Related to Stock Entry" />
                            }
                            {
                                accSingleData.mode === AccountingPaidMode.VIABILL &&
                                <FormControlLabel disabled value={AccountingPaidMode.VIABILL} control={<Radio />} variant="filled"
                                    size="small"
                                    label="Accounting Related to Billing" />
                            }

                            {!isAdmin && <FormControlLabel disabled={utils.isEdit} value={AccountingPaidMode.CLEAR_CASH_BALANCE} control={<Radio />} variant="filled"
                                size="small"
                                label="Clear Cash Balance" />
                            }
                            {
                                isRoleAllowed(ADMINRIGHTS) && <FormControlLabel disabled={utils.isEdit} value={AccountingPaidMode.OWNER_TRANSFER_TO_BANK} control={<Radio />} variant="filled"
                                    size="small"
                                    label="Owner Transfer Cash to Bank" />
                            }
                            {
                                (hotelSales && isRoleAllowed(HOTELSALES)) && <FormControlLabel disabled={utils.isEdit} value={AccountingPaidMode.VIAHOTEL} control={<Radio />} variant="filled"
                                size="small"
                                label="Accounting Related to Hotel Management" />
                            }
                        </RadioGroup>
                    </Grid>
                    {
                        (parseInt(accSingleData.mode) === AccountingPaidMode.VIAHOTEL) && <Grid item md={8} sm={8} xs={12} lg={8}>
                            <TextField
                                required
                                id="hotelBookingNo"
                                name="hotelBookingNo"
                                disabled={utils.isEdit}
                                onChange={handleInputChange}
                                variant="filled"
                                size="small"
                                label="Enter Hotel Booking No"
                                value={accSingleData.hotelBookingNo}
                                fullWidth
                                helperText="Write Booking No After #(Eg: Booking Id# 0-1 then 0-1)"
                            />
                        </Grid>
                    }

                    {
                        (parseInt(accSingleData.mode) === AccountingPaidMode.VIASTOCK) &&

                        <Grid item md={8} sm={8} xs={12} lg={8}>
                            <Autocomplete
                                id="stock-select"
                                options={stockData}
                                autoHighlight
                                variant="filled"
                                onChange={handleAutoCompleteChange}
                                fullWidth
                                size="small"
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.details}
                                defaultValue={refManagementData}
                                value={refManagementData}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option.details}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Stock Details"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {
                        (parseInt(accSingleData.mode) === AccountingPaidMode.VIASUPPLIER) &&

                        <Grid item md={8} sm={8} xs={12} lg={8}>
                            <Autocomplete
                                id="supplier-select"
                                disabled={supplierEdit}
                                options={supplierData}
                                autoHighlight
                                variant="filled"
                                size="small"
                                onChange={handleAutoCompleteChange}
                                fullWidth
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.details}
                                defaultValue={refManagementData}
                                value={refManagementData}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option.details}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Supplier Information"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    }

                    {
                        (parseInt(accSingleData.mode) === AccountingPaidMode.VIABILL) &&
                        <Grid item md={8} sm={8} xs={12} lg={8}>
                            <TextField
                                required
                                id="refId"
                                name="refId"
                                disabled
                                variant="filled"
                                size="small"
                                label="Bill Ref No"
                                value={accSingleData.billNo}
                                fullWidth
                            />
                        </Grid>
                    }

                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <FormLabel component="legend" sx={{
                            fontSize: "large",
                        }}>Select Balance Type</FormLabel>
                        <RadioGroup row aria-label="drCr"
                            size="small"
                            name="drCr" value={accSingleData.drCr} onChange={handleInputChange}>
                            <FormControlLabel disabled={getDefDebitCreditDisableStatus(accSingleData)} value={DEBIT} control={<Radio />} variant="filled"
                                size="small"
                                label="Debit" />
                            <FormControlLabel disabled={getDefDebitCreditDisableStatus(accSingleData)} value={CREDIT} control={<Radio />} variant="filled"
                                size="small"
                                label="Credit" />
                            {
                                accSingleData.drCr === TRANSFER &&
                                <FormControlLabel disabled value={TRANSFER} control={<Radio />} variant="filled"
                                    size="small"
                                    label="Transfer" />
                            }
                            {
                                accSingleData.drCr === RECEIVE &&
                                <FormControlLabel disabled value={RECEIVE} control={<Radio />} variant="filled"
                                    size="small"
                                    label="Receive" />
                            }
                        </RadioGroup>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <FormLabel component="legend" sx={{
                            fontSize: "large",
                        }}>Select Paid Status</FormLabel>
                        <RadioGroup row aria-label="paidStatus"
                            size="small"
                            name="paidStatus" value={accSingleData.paidStatus} onChange={handleInputChange}>
                            <FormControlLabel disabled={utils.isAmountChangeAllow ? false : utils.isEdit} value={BillStatus.PAID.toString()} control={<Radio />} variant="filled"
                                size="small"
                                label="Full Pay" />
                           {
                            utils.isEdit &&  <FormControlLabel disabled={(parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK) || utils.isEdit} value={BillStatus.PARTIALLYPAID.toString()} control={<Radio />} variant="filled"
                            size="small"
                            label="Partial Pay" />
                           }
                        </RadioGroup>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <TextField
                            required
                            id="amount"
                            disabled={utils.isAmountChangeAllow ? false : utils.isEdit}
                            name="amount"
                            variant="filled"
                            size="small"
                            label="Enter Amount"
                            fullWidth
                            value={accSingleData.amount}
                            onChange={handleInputChange}
                            error={formSubmitted && !accSingleData.amount}
                            helperText={getErrorMessage('amount')}
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        {
                            (parseInt(accSingleData.paidStatus) === BillStatus.PARTIALLYPAID) &&

                            <TextField
                                required
                                id="partialAmt"
                                disabled={utils.isAmountChangeAllow ? false : utils.isEdit}
                                name="partialAmt"
                                variant="filled"
                                size="small"
                                label={parseInt(accSingleData.paidStatus) === BillStatus.PARTIALLYPAID ? "Enter Partial Amount" : "Enter Loan Amount"}
                                fullwidth
                                value={accSingleData.partialAmt}
                                onChange={handleInputChange}
                            />

                        }
                    </Grid>
                </Grid>
                <Grid container spacing={1}>

                    <Grid item md={4} sm={12} xs={12} lg={4}>
                        <FormControl>
                            <FormControlLabel name="isGstAllowed" disabled={utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)} control={<Checkbox />} checked={accSingleData.isGstAllowed} onChange={handleInputChange} label={accSingleData.isGstAllowed ? "Disable GST" : "Enable GST"} />
                        </FormControl>
                    </Grid>

                    <Grid item md={4} sm={12} xs={12} lg={4}>
                        <TextField
                            required
                            id="cgstAmt"
                            name="cgstAmt"
                            variant="filled"
                            disabled={!accSingleData.isGstAllowed || utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)}
                            size="small"
                            label="Enter CGST Amount"
                            fullwidth
                            value={accSingleData.cgstAmt}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item md={4} sm={12} xs={12} lg={4}>
                        <TextField
                            required
                            id="sgstAmt"
                            name="sgstAmt"
                            variant="filled"
                            disabled={!accSingleData.isGstAllowed || utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)}
                            size="small"
                            label="Enter SGST Amount"
                            fullwidth
                            value={accSingleData.sgstAmt}
                            onChange={handleInputChange}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12}>
                        <FormLabel id="paymentMethod" sx={{
                            fontSize: "large",
                        }}>Payment Methods:</FormLabel>
                    </Grid >
                    {
                        isRoleAllowed(ADMINRIGHTS) &&
                        <Grid item xs={12} md={2} sm={6}>
                            <FormControlLabel disabled={utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)} name="onlinePayEnb" value={ONLINEPAY} checked={isOnlinePayEnabled} control={<Checkbox />} label="Online" onChange={handleInputChange} />
                        </Grid>
                    }
                    {
                        isRoleAllowed(ADMINRIGHTS) &&
                        <Grid item xs={12} md={5} sm={6}>
                            <Autocomplete
                                id="online-select"
                                options={CART_INITIAL_STATE.onlinePayMethod}
                                autoHighlight
                                variant="filled"
                                disabled={!isOnlinePayEnabled || utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)}
                                fullWidth
                                size="small"
                                onChange={handleOnlineSelectChange}
                                isOptionEqualToValue={(option, value) => option === value}
                                getOptionLabel={(option) => option}
                                defaultValue={accSingleData.onlinePayThrough}
                                value={accSingleData.onlinePayThrough}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Search Online Pay Method"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                    />
                                )}
                            />
                        </Grid>}{
                        isRoleAllowed(ADMINRIGHTS) &&
                        <Grid item xs={12} md={5} sm={6}>
                            <TextField
                                id="onlinePay"
                                name="onlinePay"
                                variant="filled"
                                size="small"
                                disabled={!isOnlinePayEnabled || utils.isEdit || !isCashPayEnabled}
                                label="Enter Online Amount"
                                value={accSingleData.onlinePay.toString()}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>}
                    <Grid item xs={12} md={2} sm={6}>
                        <FormControlLabel name="cashPayEnb" disabled={utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK)} value={CASHPAY} checked={isCashPayEnabled} control={<Checkbox />} label="Cash" onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="cashPay"
                            name="cashPay"
                            variant="filled"
                            size="small"
                            disabled={!isCashPayEnabled || utils.isEdit || (parseInt(accSingleData.mode) === AccountingPaidMode.CLEAR_CASH_BALANCE || parseInt(accSingleData.mode) === AccountingPaidMode.OWNER_TRANSFER_TO_BANK) || !isOnlinePayEnabled}
                            label="Enter Cash Amount"
                            value={accSingleData.cashPay.toString()}
                            onChange={handleInputChange}
                            fullWidth
                        /> </Grid>
                    <Grid item md={4} sm={5} xs={12}>
                        <TextField
                            id="message"
                            name="message"
                            disabled={utils.isAmountChangeAllow ? false : utils.isEdit}
                            variant="filled"
                            size="small"
                            rows={3}
                            multiline
                            label="Enter Description"
                            fullWidth
                            value={accSingleData.message}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                {
                    accSingleData.dues !== 0.0 &&
                    <Grid container spacing={1} justifyContent="center"
                        alignItems="center" sx={{
                            padding: 0, margin: 0,
                        }}>

                        <Grid md={12} sm={12} xs={12} lg={12}>
                            <Typography variant="h5" style={{ color: "#0c1485" }}>
                                {"Remaining Dues: " + accSingleData.dues}
                            </Typography>
                        </Grid>

                    </Grid>
                }
            </div>
        </React.Fragment>
    );
};

const renderDialogAction = () => {
    return (
        <React.Fragment>
            {
                (!utils.isEdit || utils.submitAllowed) ?
                    <Button variant="contained" color="primary" onClick={handleSubmitAccountEntry}>
                        Add
                    </Button> : utils.clearDues ?
                        <Button variant="contained" color="primary" onClick={handleClearDues}>
                            Clear Dues
                        </Button> : null
            }
            <Button variant="contained" color="primary" onClick={handleDialogClose}>
                Dismiss
            </Button>
        </React.Fragment>
    );
};

const clearData = () => {
    //console.log("clearData",refManagementData);
    setSingleAccData(INITIAL_ACC_DATA);
    setRefmgmtData({
        ...refManagementData,
        supplierId: "",
        supplierName: "",
        stockRefNo: "",
        billNo: "",
        details: ""
    });
    setSupplierEdit(false);
};



const handleDialogClose = () => {
    // console.log("handleDialogClose");
    clearData();
    setUtils({
        ...utils,
        isEdit: false,
        showDialog: false,
        snackBarOpen: false,
        snackBarMsg: "",
        isAmountChangeAllow: false,
        clearDues: false,
        submitAllowed: false,
    });
    setIsLoading(false);
};

const getErrorMessage = (field) => {
    if (!formSubmitted) {
        return;
    } else if (!accSingleData[field]) {
        return 'Field is required';
    }
}

const isValid = () => {
    let isAmountValid = true;

    if (!accSingleData.amount) {
        isAmountValid = false;
    }

    return isAmountValid;
}

const options = {
    responsive: true,
    maintainAspectRatio: false, // This allows you to control the chart dimensions
};


const TwoPieCharts = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '270px', height: '270px' }}>
                <Pie data={userPieDataToday} options={options} />
            </div>
            <div style={{ width: '270px', height: '270px' }}>
                <Pie data={userPieData} options={options} />
            </div>
        </div>
    );
};

var headerNameList = columns
        .filter(obj => obj.filterable) // Filter based on the condition
        .map(obj => ({ key: obj.field, value: obj.headerName }));

    const INITIAL_FILTER = {
        key: headerNameList[0].key, value: ""
    };
    const [filt, setFilt] = useState(INITIAL_FILTER);
    const handleFilterQuery = (mPage,mPageSize,filtData,pWay) => {
        //console.log("handleFilterQuery",filtData);
        if (filtData == null) {
            let data = {
                jcJson: {
                    shopHash: storeActiveShopHash.id,
                    apiToken: CART_INITIAL_STATE.apiToken,
                }
            };
            setIsLoading(true);
            setFilt(INITIAL_FILTER);
            getAccountingData(mPage,mPageSize,data,pWay);
        } else {
            setIsLoading(true);
            setFilt(filtData);
            let mKey = 'f'+filtData.key[0].toUpperCase() + filtData.key.slice(1);
            let data = {
                jcJson: {
                    shopHash: storeActiveShopHash.id,
                    apiToken: CART_INITIAL_STATE.apiToken,
                    [mKey]:filtData.value,
                }
            };
            console.log("handleFilterQuery",data);
            getAccountingData(mPage,mPageSize,data,pWay);
        }
    }


return (
    <React.Fragment>
        <div>
            <Snackbar
                open={utils.snackBarOpen}
                autoHideDuration={3000}
                message={utils.snackBarMsg}
                onClose={handleSnackClose}
            />
        </div>
        {
            isLoading ? <Loader /> : <Paper
                elevation={0}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "background.paper",
                    boxShadow: 9,
                    borderRadius: 4,
                    padding: 2,
                }}
            >
                <Grid container spacing={0} display="flex"
                    justifyContent="center"
                    alignItems="center">
                    {
                        (showData && showPie) &&
                        <Grid item md={5} sm={12} xs={12} lg={5}>
                            {TwoPieCharts()}
                        </Grid>
                    }
                    {
                        showData && <Grid item md={7} sm={12} xs={12} lg={7}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" className="rate_card">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                {
                                                    listAcc.length > 1 && <Autocomplete
                                                        id="acc-select"
                                                        options={listAcc}
                                                        autoHighlight
                                                        variant="filled"
                                                        onChange={handleAccTypeChange}
                                                        
                                                        style={{
                                                            width: "80%",
                                                            minHeight: "1px",
                                                            height:"10%",
                                                            boxShadow:"0px 3px 3px -2px #fff, 0px 3px 4px 0px #fff, 0px 1px 8px 0px #0000001f"
                                                        }}
                                                        size="small"
                                                        isOptionEqualToValue={(option, value) => option === value}
                                                        getOptionLabel={(option) => option}
                                                        defaultValue={mAccType}
                                                        value={mAccType}
                                                        renderOption={(props, option) => (
                                                            <Box
                                                                component="li"
                                                                {...props}
                                                            >
                                                                {option}
                                                            </Box>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Select Account Type"
                                                                InputLabelProps={{
                                                                    style: { color: '#ffff',fontStyle:'bold' },
                                                                }}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    style:  {color:'#ffff'}
                                                                }}
                                                                sx={{
                                                                    '&. MuiSvgIcon-root ' : {
                                                                        color: 'white'
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell align="center">Cash(₹)</TableCell>
                                            <TableCell align="center">Online(₹)</TableCell>
                                            <TableCell align="center">T.Amt(₹)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(dayBookDesigns).map((val, index) => {
                                            return (
                                                <TableRow
                                                    className="rate_card--row"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center">{val.tye}</TableCell>
                                                    <TableCell align="center" >{val.cash}</TableCell>
                                                    <TableCell align="center" >{val.online}</TableCell>
                                                    <TableCell align="center" >{val.total}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    }

                    <Grid item md={3} sm={6} xs={12} lg={3}>
                        <div style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(event) => setShowData(!showData)}
                            >
                                {showData ? "Hide Above Details(-)" : "Show Details(+)"}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12} lg={3}>
                        <div style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(event) => {
                                    if (isRoleAllowed(ACCCREATE)) {
                                        setUtils({
                                            ...utils,
                                            showDialog: true,
                                        });
                                    } else {
                                        showDialogBoxWithPaper("You Are not allowed to Create New Entry.Contact the Owner");
                                    }
                                }}
                            >
                                Add New Account Entry
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        }

        {paginationWay === 'client' &&
            <Grid item md={12} sm={12} xs={12} lg={12} style={{ display: "flex", justifyContent: "center", color: "#01333e" }}>
                <center>
                    <Typography variant="h5" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >{"*Note: Below data's are till " + todayMinus10 + " old. For more go to Owner Dashbord, generate Balance-Report"}</Typography>
                </center>
            </Grid>
        }
        <Grid item md={12} sm={12} xs={12} lg={12}>
            <div id="accounting">
                <CustomDataGrid
                    rows={accountData}
                    columns={columns}
                    loading={isLoading}
                    mDensity="compact"
                    paginationMode={paginationWay}
                    paginationModel={paginationModel}
                    onPageDataChange={(e) => handleFilterQuery(e, paginationModel.pageSize, null,paginationWay)}
                    cbPageSizeChange={(mPageSize) => handleFilterQuery(paginationModel.page, mPageSize, null,paginationWay)}
                    mFilterPanel={paginationWay === "client"  ? ()=> <GridFilterPanel/> : () => <CustomFilterInput
                        items={headerNameList}
                        mFilt={filt}
                        onSubmit={(e) => handleFilterQuery(paginationModel.page, paginationModel.pageSize, e,paginationWay)}
                        onClear={() => handleFilterQuery(paginationModel.page, paginationModel.pageSize, null,paginationWay)}
                    />}
                    
                // getRowClassName={(params) => {
                //     return params.row.drCr === 'Cr' ? "success" : "error";
                //   }}
                />
            </div>
        </Grid>

        <DialogBox
            showDialog={utils.showDialog}
            title={utils.isEdit ? "View Acccount Entry" : "Add New Account Entry"}
            onClose={handleDialogClose}
            dialogContent={renderDialogContent()}
            dialogActions={renderDialogAction()}
        ></DialogBox>
    </React.Fragment>
);
}