import React, { useEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, TableBody, TextField, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DialogBox from "../global/DialogBox";
import { ComboSelMethod, SHOWRESTOFEWDATA, SHOWCOMBOOFFERS, getPreUrlAccordingToCart, CART_INITIAL_STATE, SUCCESS, OfferType, ItemStatus, SETCOMBOOFFERS, isEmpty, isNumberEmpty, AXIOS_TIMEOUT, isRoleAllowed, INVENTORYCREATE } from "../../constants/constant";
import '../resturant/RestoProductStock.css'
import CustomDataGrid from "../global/CustomDataGrid";
import imageUploadService from "../../service/imageUploadService";
import { snpAxios } from "../global/api";

export default function ComboOffer() {
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };

    const INITIAL_COMBO_OFFER = {
        id: "",
        comboName: "",
        statusId: "",
        attachment: "",
        offerTypeId: "",
        itemList: [],
        finalAmount: 0.0,
        amount: 0.0,
        offerAmt: 0.0,
        offerType: OfferType.No_Offer,
        status: ItemStatus.ACTIVE,
    };
    const INITIAL_COMBO_SEL = {
        id: "",
        index: -1,
        itemId: "",
        itemName: "",
        name: "",
        itemCount: 0.0,
        pastAmt: 0.0,
        amount: 0.0,
        type: ComboSelMethod.FullPay,
        typeId: "",
    };
    const INITIAL_SEL_METHOD = {
        id: 0,
        value: "",
    };
    const INITIAL_RESTO_DATA = {
        id: "",
        amt: "",
        tname: "",
        name: "",
    };

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const [showDialog, setShowDialog] = useState(false);
    const [isEditProduct, setEditProduct] = useState(false);

    const [comboOffer, setComboOffer] = useState(INITIAL_COMBO_OFFER);
    const [listComboOffer, setListComboOffer] = useState([]);

    const [selMethod, setSelectMethod] = useState(INITIAL_SEL_METHOD);
    const [listSelMethod, setListSelMethod] = useState([]);

    const [selectedComboSel, setSelectedComboSel] = useState(INITIAL_COMBO_SEL);
    const [listComboSel, setListComboSel] = useState([]);

    const [selectedRestoData, setSelectedRestoData] = useState(INITIAL_RESTO_DATA);
    const [restoData, setRestoData] = useState([]);
    const [tempRestoData, setTempRestoData] = useState([]);
    const [uploadedDocument, setUploadedDocument] = useState([]);

    useEffect(() => {
        //console.log("useEffect",CART_INITIAL_STATE)
        getRestoDetails();
    }, [storeActiveShopHash,storeApiUrl]);

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleDialogClose = () => {
        console.log("handleDialogClose");
        setEditProduct(false);
        setComboOffer(INITIAL_COMBO_OFFER);
        setListComboSel([]);
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedRestoData(INITIAL_RESTO_DATA);
        setSelectedComboSel(INITIAL_COMBO_SEL);
        setTempRestoData([]);
        setShowDialog(false);
        getComboOffers();
    };

    const columns = [
        {
            field: "attachment",
            headerName: "Image",
            width: 90,
            renderCell: (rowData) => (
                <div>
                    <img
                        alt=""
                        style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                        src={storeApiUrl.BASE_IMAGE_URL + rowData.row.attachment}
                    ></img>
                </div>
            ),
        },
        { field: 'comboName', headerName: 'Combo Name', width: 150 },
        { field: 'offerTypeId', headerName: 'OfferType', width: 90 },
        { field: 'offerAmt', headerName: 'OfferAmt.', width: 90 },
        { field: 'amount', headerName: 'Amt.', width: 90 },
        { field: 'finalAmount', headerName: 'f.Amt', width: 90 },
        { field: 'statusId', headerName: 'Status', width: 100 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(INVENTORYCREATE)) {
                                handleCellClick(event, cellValues);
                            } else {
                                setSnpComponent({
                                    ...snpComponent,
                                    showDialog: true,
                                    title: "",
                                    iconReq: true,
                                    negativeReq: true,
                                    subTitle: "You Are not allowed to Update Combo Details.Contact the Owner",
                                    negativeAction: "Got It!",
                                })
                            }
                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];
    const getRestoDetails = async () => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWRESTOFEWDATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                // console.log("response  => ", response.data);
                getComboOffers();
                if (response.data.status === SUCCESS) {
                    setRestoData(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                getComboOffers();
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };
    const getComboOffers = () => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWCOMBOOFFERS;

        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        snpAxios.post(cUrl, data)
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                //console.log("getComboOffers", response.data);
                if (response.data.status === SUCCESS) {
                    var array = [...response.data.jcJson.value_1];
                    array.splice(0,1);
                    setListSelMethod(array);
                    setListComboOffer(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("getComboOffers error ", error);
            });
    };
    const handleImageUpload = async () => {
        if (!uploadedDocument) {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            })
            handleSubmit("");
        } else {
            setSnpComponent({
                ...snpComponent,
                isLoader: true,
            })
            let formData = new FormData();
            formData.append('fnm', CART_INITIAL_STATE.apiToken);
            formData.append("file", uploadedDocument[0]);
            await imageUploadService.upload(storeApiUrl.BACKEND_BASE_URL, getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
                //console.log("handleImageUpload", response.data);
                if (response.data.folderName) {
                    handleSubmit(response.data.folderName);
                } else {
                    handleSubmit("");
                }

            }
            ).catch(error => {
                handleSubmit("");
                console.log("handleImageUpload = > ", error);
                // window.location.href = "#/error";
            });
        }
    };
    const handleSubmit = (folderName) => {
        //console.log("handleSubmit=1>",listComboSel , comboOffer);
        let isError = false;
        if (isEmpty(comboOffer.comboName)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Combo Name",
                snackBarOpen: true,
            })
        }

        if (listComboSel.length <= 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Items",
                snackBarOpen: true,
            })
        }
        if (!isError) {
            let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SETCOMBOOFFERS;
            let filledData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    id: comboOffer.id,
                    comboName: comboOffer.comboName,
                    itemList: listComboSel,
                    status: parseInt(comboOffer.status),
                    offerType: comboOffer.offerType,
                    offferAmt: comboOffer.offerAmt,
                    amount: comboOffer.amount,
                    finalAmount: comboOffer.finalAmount,
                    shopHash: storeActiveShopHash.id,
                    attachment: folderName,
                }
            };
            //console.log("handleSubmit => ", filledData);
            handleDialogClose();
            snpAxios.post(catgUrl,filledData)
            .then(response => {
                //console.log("responses => ", response.data);
                if (response.data.status === SUCCESS) {
                    getComboOffers();
                }
            }).catch(error => {
                console.log("error : ", error);
                // window.location.href = "#/error";
            });
            handleDialogClose();
        }
    };

    const changeInRestoData = (name, added) => {
        var array = [...tempRestoData];
        var index = array.findIndex(singleValue => singleValue.tname === name);
        array[index].added = added;
        setTempRestoData(array);
    }
    function checkIfItemPresent(name, array) {
        //console.log("checkIfItemPresent ",array,name);
        return array.find((key) => {
            return (key.itemName === name);
        });
    }
    const handleCellClick = (param, event) => {
        let itemList = event.row.itemList;
        //console.log("handleCellClick=>",itemList);
        const newArray = restoData.map(a => ({ ...a, added: checkIfItemPresent(a.tname, itemList) }));//Because of Shallow Copy,I used this method to convert to deep Copy
        //console.log("handleCellClick=>",newArray);
        setListComboSel(itemList);
        setTempRestoData(newArray);
        setEditProduct(true);
        setComboOffer({
            ...comboOffer, ...event.row
        });
        setShowDialog(true);
    };
    const getKeyByValue = (value) => {
        //console.log("getKeyByValue",ComboSelMethod,value);
        return Object.keys(ComboSelMethod).find(key => ComboSelMethod[key] === value);
    }
    const handleSelectChange = (event, resto) => {
        //console.log("handleSelectChange",resto);
        if (resto != null) {
            if (resto.added) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Item " + resto.tname + " is already added",
                })
            } else {
                let comboselMethodVal = getKeyByValue(ComboSelMethod.FullPay);
                //console.log("comboselMethodVal",comboselMethodVal);
                setSelectedComboSel({
                    ...selectedComboSel,
                    itemId: resto.id,
                    itemName: resto.tname,
                    name: resto.name,
                    itemCount: 1.0,
                    pastAmt: parseFloat(resto.amt),
                    amount: parseFloat(resto.amt),
                    index: -1,
                    type: ComboSelMethod.FullPay,
                    typeId: comboselMethodVal,
                    id: "",
                });
                setSelectedRestoData({
                    ...selectedRestoData,
                    id: resto.id,
                    amt: parseFloat(resto.amt),
                    tname: resto.tname,
                    name: resto.name,
                });
                setSelectMethod({
                    ...selMethod,
                    id: ComboSelMethod.FullPay,
                    value: comboselMethodVal,
                });
            }
        } else {
            setSelectMethod(INITIAL_SEL_METHOD);
            setSelectedRestoData(INITIAL_RESTO_DATA);
            setSelectedComboSel(INITIAL_COMBO_SEL);
        }
    };

    const handleSelectMethod = (event, data) => {
        //console.log("handleSelectMethod",data);
        if (data != null) {
            setSelectMethod(data);
            setSelectedComboSel({
                ...selectedComboSel,
                type: data.id,
                typeId: data.value,
                amount: (data.id === ComboSelMethod.Free) ? 0 : parseFloat(selectedComboSel.pastAmt),
                itemCount: 1,
            });
        } else {
            setSelectMethod({
                ...selMethod,
                id: ComboSelMethod.FullPay,
                value: getKeyByValue(ComboSelMethod.FullPay),
            });
            setSelectedComboSel({
                ...selectedComboSel,
                type: ComboSelMethod.FullPay,
                typeId: getKeyByValue(ComboSelMethod.FullPay),
                amount: parseFloat(selectedComboSel.pastAmt),
                itemCount: 1,
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'imgPath') {
            let file = event.target.files[0];
            if (!file) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Selected! Please Try Again",
                    snackBarOpen: true,
                })
            } else if (file.size > 5242880) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Invalid File Size Should be less than 5Mb",
                    snackBarOpen: true,
                })
            } else if (!(file.type.includes("image"))) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select Image",
                    snackBarOpen: true,
                })
            } else {
                setUploadedDocument(prevDocuments => [...prevDocuments, file]);
            }
        } else if (name === 'itemCount') {
            let curr = parseFloat(value) * parseFloat(selectedComboSel.pastAmt);
            setSelectedComboSel({
                ...selectedComboSel,
                amount: curr,
                itemCount: value,
            });
        } else if (name === 'amount') {
            setSelectedComboSel({
                ...selectedComboSel,
                amount: parseFloat(value),
            });
        } else if (name === 'comboName') {
            setComboOffer({
                ...comboOffer,
                comboName: value,
            });
        } else if (name === 'offerType') {
            doTotal(null, parseInt(value), comboOffer.offerAmt, false, 0.0);
        } else if (name === 'offerAmt') {
            doTotal(null, comboOffer.offerType, parseFloat(value), false, 0.0);
        } else if (name === 'status') {
            setComboOffer({
                ...comboOffer,
                status: value,
            });
        }
    };



    const handleAddOffer = (event) => {
        if (selectedComboSel.index >= 0) {
            //console.log("handleAddOffer Edit",selectedComboSel);
            let listComboSelCopy = listComboSel;
            if (selectedComboSel.index >= 0 && listComboSel[selectedComboSel.index]) {
                let oldPrice = 0.0;
                doTotal(selectedComboSel, comboOffer.offerType, comboOffer.offerAmt, false, oldPrice);
                listComboSelCopy[[selectedComboSel.index]] = { ...selectedComboSel }
                setListComboSel([...listComboSelCopy]);
            }
        } else {
            let size = (listComboSel.length === 0) ? 0 : (listComboSel.length - 1);
            selectedComboSel.index = size;
            //console.log("handleAddOffer Add",selectedComboSel);
            changeInRestoData(selectedComboSel.itemName, true);
            doTotal(selectedComboSel, comboOffer.offerType, comboOffer.offerAmt, false, 0.0);
            setListComboSel([
                ...listComboSel, selectedComboSel
            ]);
        }
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedRestoData(INITIAL_RESTO_DATA);
        setSelectedComboSel(INITIAL_COMBO_SEL);
    };

    const handleDelOffer = (e) => {
        //console.log("handleDelOffer",selectedComboSel);
        var listComboSelCopy = [...listComboSel];
        listComboSelCopy.splice(selectedComboSel.index, 1);
        setListComboSel([...listComboSelCopy]);
        doTotal(selectedComboSel, comboOffer.offerType, comboOffer.offerAmt, true, 0.0);
        changeInRestoData(selectedComboSel.itemName, false);
        setSelectMethod(INITIAL_SEL_METHOD);
        setSelectedRestoData(INITIAL_RESTO_DATA);
        setSelectedComboSel(INITIAL_COMBO_SEL);
    };

    const handleTableEditClick = (mSelectedComboSel) => {
        //console.log("handleTableEditClick",mSelectedComboSel);
        setSelectedComboSel(mSelectedComboSel);
        setSelectedRestoData({
            ...selectedRestoData,
            id: mSelectedComboSel.itemId,
            amt: mSelectedComboSel.amount,
            tname: mSelectedComboSel.itemName,
            name: mSelectedComboSel.name,
        });
        setSelectMethod({
            ...selMethod,
            id: mSelectedComboSel.type,
            value: mSelectedComboSel.typeId,
        });
    };

    const doTotal = (mSelectedComboSel, mOfferType, mOfferAmt, isDel, oldPrice) => {
        console.log("init dototal", "mOfferType: "+mOfferType,"mOfferAmt: "+ mOfferAmt,"isDel: "+ isDel,"oldPrice: "+ oldPrice);
        if (isNumberEmpty(mOfferAmt))
            mOfferAmt = 0.0;
        if (isNumberEmpty(oldPrice))
            oldPrice = 0.0;
        if (isNumberEmpty(mOfferType))
            oldPrice = OfferType.No_Offer;
        let mamount = parseFloat(comboOffer.amount);
        let delAmt = 0.0;
        let mfinalAmount = parseFloat(comboOffer.finalAmount);
        let isMult = false;
        let tTotal = 0.0;
        if (mSelectedComboSel != null) {
            console.log("mSelectedComboSel dototal", mSelectedComboSel);
            isMult = (mSelectedComboSel.type === ComboSelMethod.OfferPay
                    || mSelectedComboSel.type === ComboSelMethod.Free);
            
            if (isDel) {
                mamount = mamount - parseFloat(mSelectedComboSel.pastAmt);
                mfinalAmount = mfinalAmount - parseFloat(mSelectedComboSel.amount);
            } else {
                mamount = mamount + parseFloat(mSelectedComboSel.pastAmt);
                mfinalAmount = mfinalAmount + parseFloat(mSelectedComboSel.amount);
            }
            mamount = mamount - parseFloat(oldPrice);
            mfinalAmount = mfinalAmount - parseFloat(oldPrice);
            if (mOfferType === OfferType.Multi && !isMult) {
                mOfferType = OfferType.No_Offer;
                tTotal = mfinalAmount;
            }
            
        } else
            tTotal = mamount;

        if (isMult) { //Recovering Final Amount
            if (parseInt(mOfferType) === OfferType.Flat) {
                mfinalAmount = mfinalAmount + parseFloat(mOfferAmt);
                mOfferAmt = 0.0;
            } else if (parseInt(mOfferType) === OfferType.Percentage) {
                mfinalAmount = mfinalAmount
                    + ((mfinalAmount * parseFloat(mOfferAmt)) / 100);
                mOfferAmt = 0.0;
            }
            mOfferType = OfferType.Multi;
        } else {
            if (parseInt(mOfferType) === OfferType.No_Offer) {
                mOfferAmt = 0.0;
                mfinalAmount = tTotal;
            } else if (parseInt(mOfferType) === OfferType.Flat) {
                tTotal = tTotal - parseFloat(mOfferAmt);
                mfinalAmount = tTotal;
            } else if (parseInt(mOfferType) === OfferType.Percentage) {
                tTotal = tTotal
                    - ((tTotal * parseFloat(mOfferAmt)) / 100);
                if (isDel) {
                    delAmt = mSelectedComboSel.amount
                        - ((mSelectedComboSel.amount * parseFloat(mOfferAmt)) / 100);
                        tTotal = tTotal + delAmt;
                }
                mfinalAmount = tTotal;
            }
            
        }
        
        console.log("doTotal => ","mOfferType: "+mOfferType,"mOfferAmt: "+mOfferAmt,"isDel: "+isDel,"oldPrice: "+oldPrice ,"mamount: "+mamount ,"mfinalAmount: "+ mfinalAmount)  
        setComboOffer({
            ...comboOffer,
            offerAmt: parseFloat(mOfferAmt),
            offerType: parseInt(mOfferType),
            amount: parseFloat(mamount),
            finalAmount: mfinalAmount.toFixed(2),
        })
    }

    const renderDialogContent = () => {
        return (
            <div>
                <Grid container justifyContent="center" alignItems="center" spacing={0} style={{ border: "1px solid black", padding: "5px 5px", margin: "5px" }}>
                    <Grid item md={6} sm={6} xs={12} lg={6} >
                        <Autocomplete
                            id="restoSelect"
                            variant="filled"
                            size="small"
                            options={tempRestoData}
                            onChange={handleSelectChange}
                            getOptionLabel={(option) => option.tname}
                            defaultValue={selectedRestoData}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    <Typography variant="p" style={{ color: (option.added ? "#7f8485" : "#000000") }}>
                                        {option.tname}
                                    </Typography>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a Food Item"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                    style={{ width: "100%" }}
                                />
                            )}
                        />
                    </Grid>
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="pastAmt"
                                disabled
                                label="Amount"
                                name="pastAmt"
                                value={selectedComboSel.pastAmt}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 &&
                        <Grid item md={7} sm={8} xs={12} lg={7} >
                            <Autocomplete
                                id="type"
                                variant="filled"
                                size="small"
                                options={listSelMethod}
                                onChange={handleSelectMethod}
                                defaultValue={selMethod}
                                getOptionLabel={(option) => option.value}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                    >
                                        {option.value}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pay Type"
                                        inputProps={{
                                            ...params.inputProps,
                                        }}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="itemCount"
                                type="number"
                                inputMode="numeric"
                                label="Enter Quantity"
                                name="itemCount"
                                onChange={handleInputChange}
                                value={selectedComboSel.itemCount}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid item md={6} sm={6} xs={12} lg={6} >
                            <TextField
                                id="amount"
                                type="number"
                                label="Enter New Amount"
                                inputMode="numeric"
                                name="amount"
                                onChange={handleInputChange}
                                disabled={!(selectedComboSel.type === ComboSelMethod.OfferPay)}
                                value={selectedComboSel.amount}
                                variant="filled"
                                size="small"
                                style={{ width: "100%" }}
                                inputProps={{ min: '0' }}
                            />
                        </Grid>
                    }
                    {
                        selectedComboSel.pastAmt > 0 && <Grid md={6} sm={6} xs={12} lg={6}>
                            <center>
                                <Button variant="filled" color="primary" className="rate_card--button" onClick={handleAddOffer}>
                                    Add
                                </Button>
                            </center>
                        </Grid>
                    }

                    {/* {
                        selectedComboSel.index >= 0 && <Grid item md={6} sm={6} xs={12} lg={6} className="rate_card__button--container">
                            <Button variant="filled" color="primary" className="rate_card--button" onClick={handleDelOffer}>
                                Delete
                            </Button>
                        </Grid>
                    } */}
                </Grid>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" className="rate_card">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Food Name</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">P.Amt</TableCell>
                                <TableCell align="center">N.Amt</TableCell>
                                <TableCell align="center">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(listComboSel).map((val, index) => {
                                return (
                                    <TableRow
                                        className="rate_card--row"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="center">{val.name}</TableCell>
                                        <TableCell align="center" >{val.itemCount}</TableCell>
                                        <TableCell align="center" >{val.typeId}</TableCell>
                                        <TableCell align="center" >{val.pastAmt}</TableCell>
                                        <TableCell align="center" >{val.amount}</TableCell>
                                        <TableCell align="center" ><EditIcon onClick={() => handleTableEditClick(val)} /></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container justifyContent="center" alignItems="center" spacing={0} sx={{
                    mt: 1,
                }}>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                                id="comboName"
                                label="Enter Combo Name"
                                variant="filled"
                                size="small"
                                name="comboName"
                                value={comboOffer.comboName}
                                onChange={handleInputChange}
                                style={{ width: "40%" }}
                            />
                        </div>
                    </Grid>
                    {comboOffer.attachment &&
                        <Grid item md={2} xs={12} lg={2} sm={2}>
                            <img
                                alt=""
                                style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                                src={storeApiUrl.BASE_IMAGE_URL + comboOffer.attachment}
                            />

                        </Grid>
                    }
                    <Grid item md={4} xs={12} lg={4} sm={4}>
                        <TextField
                            accept="image/*"
                            id="imgPath"
                            name="imgPath"
                            label=""
                            multiple
                            type="file"
                            autoHighlight
                            size="small"
                            helperText="Upload Imgae(If Any and it should be less than 5Mb.)"
                            onChange={handleInputChange}
                            fullWidth
                        /></Grid>
                    <Grid item md={12} sm={12} xs={12} lg={12}>
                        <FormLabel component="legend">Any Offer :</FormLabel>
                        <RadioGroup row aria-label="offerType" name="offerType" value={comboOffer.offerType.toString()} onChange={handleInputChange}>
                            <FormControlLabel disabled value={OfferType.Multi.toString()} control={<Radio />} label="Multi" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.Flat.toString()} control={<Radio />} label="Flat" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.Percentage.toString()} control={<Radio />} label="Percentage" />
                            <FormControlLabel disabled={comboOffer.offerType === OfferType.Multi} value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                        </RadioGroup>
                    </Grid>
                    {
                        (comboOffer.offerType > 1 && comboOffer.offerType < 6) &&
                        <Grid item md={8} sm={8} xs={12} lg={8}>
                            <TextField
                                required
                                id="offerAmt"
                                name="offerAmt"
                                variant="filled"
                                size="small"
                                label="Enter Offer Value"
                                value={comboOffer.offerAmt.toString()}
                                onChange={handleInputChange}
                                style={{ width: "100%" }}
                            /> </Grid>
                    }
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <Typography variant="p" style={{
                            color: "#02056e",
                            fontWeight: 'bold', whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                        }}>
                            Selling Price: &#8377;{" " + comboOffer.amount}
                            {"       "}
                            After Offer: &#8377;{" " + comboOffer.finalAmount}
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} lg={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Enter Item Status</FormLabel>
                            <RadioGroup aria-label="status" name="status" value={comboOffer.status.toString()} onChange={handleInputChange} row={true}>
                                <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                                <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                <Button variant="contained" color="primary" onClick={handleImageUpload}>
                    {!isEditProduct ? "Add" : "Update"}
                </Button>
                <Button variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </Button>
            </React.Fragment>
        );
    };

    const renderSnpContent = () => {
        return (
            <div>
                <Button variant="contained" color="primary" style={{ float: 'right' }} className="float-right"
                    onClick={(event) => {
                        //console.log("Combo",restoData);
                        if (isRoleAllowed(INVENTORYCREATE)) {
                            const newArray = restoData.map(a => ({ ...a }));//Because of Shallow Copy,I used this method to convert to deep Copy
                            //console.log("Combo 2",newArray);
                            setTempRestoData(newArray);
                            setShowDialog(true);
                        } else {
                            setSnpComponent({
                                ...snpComponent,
                                showDialog: true,
                                title: "",
                                iconReq: true,
                                negativeReq: true,
                                subTitle: "You Are not allowed to Add Combo Details.Contact the Owner",
                                negativeAction: "Got It!",
                            });
                        }
                    }}>
                    Add New Combo Offer
                </Button>
                <br /> <br />
                <DialogBox showDialog={showDialog}
                    title="Combo Offer"
                    onClose={handleDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderDialogAction()} />
                <CustomDataGrid
                    rows={listComboOffer}
                    columns={columns}
                    loading={snpComponent.isLoader}
                />
            </div>
        );
    };

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            title={snpComponent.title}
            isLoader={snpComponent.isLoader}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={(e) => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            content={renderSnpContent()}
            onSnackClose={handleSnackClose}
            cancelExtra={snpComponent.cancelExtra}
            onCancel={""}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            handleSubmit={""}
        />
    );
}