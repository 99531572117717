import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Typography,
  Button,
} from "@mui/material";
import DialogBox from "../global/DialogBox";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import SlideshowIcon from '@mui/icons-material/Slideshow';
// import { SketchPicker } from "react-color";
import {
  AXIOS_TIMEOUT,
  CART_INITIAL_STATE,
  getPreUrlAccordingToCart,
  GET_USER_THEME_DATA,
  SAVE_USER_THEME_DATA,
} from "../../constants/constant";
import _ from "lodash";
import ImageUploadService from "../../service/imageUploadService";
import { useSelector } from "react-redux";
import SnpComponent from "../global/SnpComponent";
import FAB from "../global/fab/FAB";
import { useNavigate } from "react-router-dom";
import utilities from "../../utilities/utilities";
import { snpAxios } from '../global/api';
function ThemeManager() {
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: () => {},
    handleSnpDialogClose : () => { },
    handleSnpCancel : () => { },
  };
  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
  const fabActions = [
    {
      label: "Edit",
      icon: (<EditIcon fontSize="inherit" />),
      onClick: (() => setShowBusinessThemeEditor(true)),
    },
    {
      label: "Reset",
      icon: (<RefreshIcon fontSize="inherit" />),
      onClick: (() => handleResetBusinessTheme()),
    },
    {
      label: "Save",
      icon: (<SaveIcon fontSize="inherit" />),
      onClick: (() => handleUserTheme()),
    },
    {
      label: "Preview Ecart",
      icon: (<SlideshowIcon fontSize="inherit" />),
      onClick: (() => handlePreviewEcart()),
    },
  ];
  const navigate = useNavigate();


  const [businessTheme, setBusinessTheme] = useState({
    jcId: "",
    businessBackgroundColor: "#ffffff",
    // businessBackgroundImage: "/images/cat-demo3.png",
    businessBackgroundImage: null,
  });
  const [userThemeData, setUserThemeData] = useState({
    id: 0,
    businessBackgroundColor: "#ffffff",
    businessBackgroundImage: null,
    catgThemes: [
      {
        categoryId: 0,
        cateogoryName: "",
        categoryBackgroundColor: "#ffffff",
        categoryFontColor: "#000000",
        cardBackGroundImage: null,
        categoryFontSize: 26,
        itemBackgroundColor: "",
        itemFontColor: "#000000",
        itemFontSize: 10,
      },
    ],
  });
  const [isDataLoaded, setDataLoded] = useState(false);
  const [selectedBusinessImage, setSelectedBusinessImage] = useState(null);
  const [categoryImageSelectedError, setCategoryImageSelectedError] =
    useState(false);
  const [businessImageSelectedError, setBusinessImageSelectedError] =
    useState(false);
  const [categoryTheme, setCategoryTheme] = useState({
    id: "",
    categoryId: 0,
    categoryName: "",
    categoryBackgroundColor: "#ffffff",
    categoryFontColor: "#000000",
    cardBackGroundImage: "",
    categoryFontSize: 26,
    itemBackgroundColor: "",
    itemFontColor: "#000000",
    itemFontSize: 10,
    selectedCategoryImage: null,
  });
  // let initialUserTheme = usePrevious(_.mapKeys(userThemeData.catgThemes,"categoryId"))
  const [initialUserTheme, setInitialUserTheme] = useState({});
  const [categoryThemeList, setCategoryThemeList] = useState(initialUserTheme);
  // const [selectedCategoryImage, setSelectedCategoryImage] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState({
  //   categoryId: "",
  //   categoryName: "",
  // });
  const [showCategoryThemeEditor, setShowCategoryThemeEditor] = useState(false);
  const [showBusinessThemeEditor, setShowBusinessThemeEditor] = useState(false);

  useEffect(() => {
    //console.log("insideUseEffect: ");
    getCompleteUserThemeData();
  }, [storeCartType, storeActiveShopHash,storeApiUrl]);

  const handlePreviewEcart = () => {
    let encodedString = utilities.encode(storeActiveShopHash.id);
    // navigate({
    //   pathname: '/shop',
    //   search: '?q='+encodedString,
    // });
    window.open(`${window.location.origin}/${"#/shop?q="+encodedString}`, "_blank", "noreferrer");
  };

  const getCompleteUserThemeData = () => {
    setSnpComponent({
      ...snpComponent,
      isLoader: true,
    });
    const url =
      storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_USER_THEME_DATA;
    const data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      },
    };
    snpAxios
      .post(url, data, { timeout: AXIOS_TIMEOUT })
      .then((response) => {
        setSnpComponent({
          ...snpComponent,
          isLoader: false,
        });
        console.log("themeResJ: ", response.data.jcJson);
        setInitialUserTheme(
          _.mapKeys(response.data.jcJson.catgThemes, "categoryId")
        );
        //console.log("UsrthemeResTr: ", initialUserTheme);
        setCategoryThemeList(
          _.mapKeys(response.data.jcJson.catgThemes, "categoryId")
        );
        setUserThemeData(response.data.jcJson);
        setBusinessTheme({
          ...businessTheme,
          businessBackgroundColor: response.data.jcJson.businessBackgroundColor,
          businessBackgroundImage: response.data.jcJson.businessBackgroundImage,
        });
        setDataLoded(true);
      })
      .catch((error) => {
        setSnpComponent({
          ...snpComponent,
          isLoader: false,
        });
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  
  const handleCategoryThemeChange = (event) => {
    const { name, value } = event.target;
    if (showCategoryThemeEditor)
      setCategoryTheme({ ...categoryTheme, [name]: value });
    if (name === "cardBackGroundImage") {
      setCategoryTheme({
        ...categoryTheme,
        selectedCategoryImage: event.target.files[0],
      });
      // setSelectedCategoryImage(event.target.files[0]);
    }
    if (showBusinessThemeEditor)
      setBusinessTheme({ ...businessTheme, [name]: value });
    if (name === "businessBackgroundImage") {
      console.log("businessImgCh=> ", event.target.files[0]);
      setSelectedBusinessImage(event.target.files[0]);
    }
  };

  const handleApplyCategoryTheme = () => {
    setShowBusinessThemeEditor(false);
    setShowCategoryThemeEditor(false);
    setCategoryThemeList({
      ...categoryThemeList,
      [categoryTheme.categoryId]: categoryTheme,
    });
    clearCategoryTheme();
  };

  const clearCategoryTheme = () => {
    setCategoryTheme({
      id: "",
      categoryId: 0,
      categoryName: "",
      categoryBackgroundColor: "#ffffff",
      categoryFontColor: "#000000",
      cardBackGroundImage: "",
      categoryFontSize: 26,
      itemBackgroundColor: "#FFFFFF",
      itemFontColor: "#000000",
      itemFontSize: 10,
      selectedCategoryImage: null,
    });
  };

  const handleDialogClose = () => {
    setShowBusinessThemeEditor(false);
    setShowCategoryThemeEditor(false);
    //console.log("businessImgSt=> ", selectedBusinessImage);
  };
  const handleResetCategoryTheme = (categoryId) => {
    setShowCategoryThemeEditor(false);
    //console.log("originalThemeId: ", categoryId);
    //console.log("originalTheme: ", initialUserTheme[categoryId]);
    if (categoryId && categoryId !== 0) {
      setCategoryThemeList({
        ...categoryThemeList,
        [categoryId]: initialUserTheme[categoryId],
      });
      clearCategoryTheme();
    }
  };
  const handleResetBusinessTheme = () => {
    setShowCategoryThemeEditor(false);
    setBusinessTheme({
      ...businessTheme,
      businessBackgroundColor: userThemeData.businessBackgroundColor,
      businessBackgroundImage: userThemeData.businessBackgroundImage,
    });
    setSelectedBusinessImage(null);
  };
  
  const handleCategorBGImageUpload = async (catgTheme) => {
    //console.log("uploding cat image....");
    let isError = false;
    let updatedCatgTheme;
    if (
      !catgTheme.selectedCategoryImage ||
      catgTheme.selectedCategoryImage.size > 5242880 ||
      !catgTheme.selectedCategoryImage.type.includes("image")
    ) {
      setCategoryImageSelectedError(true);
    }
    if (!isError) {
      let formData = new FormData();
      formData.append('fnm', CART_INITIAL_STATE.apiToken);
      formData.append("file", catgTheme.selectedCategoryImage);
      await ImageUploadService.upload(storeApiUrl.BACKEND_BASE_URL,getPreUrlAccordingToCart(storeCartType), formData)
        .then((response) => {
          if (response.data.folderName) {
            console.log("catgFileName", response.data.folderName);
            updatedCatgTheme = {
              categoryId: catgTheme.categoryId,
              categoryName: catgTheme.categoryName,
              categoryBackgroundColor: catgTheme.categoryBackgroundColor,
              categoryFontColor: catgTheme.categoryFontColor,
              cardBackGroundImage: response.data.folderName,
              categoryFontSize: catgTheme.categoryFontSize,
              itemBackgroundColor: catgTheme.itemBackgroundColor,
              itemFontColor: catgTheme.itemFontColor,
              itemFontSize: catgTheme.itemFontSize,
            };
          } else {
            console.log("response.data", response.data);
          }
        })
        .catch((error) => {
          console.log("handleImageUpload = > ", error);
          setCategoryImageSelectedError(true);
        });
    }
    return updatedCatgTheme;
  };
  const handleCategoryThemeListCreation = async () => {
    let catThemeList = [];
    Object.values(categoryThemeList).forEach(async (category) => {
      if (
        category.selectedCategoryImage !== undefined &&
        category.selectedCategoryImage !== null &&
        category.selectedCategoryImage !== ""
      ) {
        var tempCatTheme = await handleCategorBGImageUpload(category);
        console.log("tCatTheme: ", tempCatTheme);
        if (tempCatTheme === undefined || tempCatTheme !== null) return false;
        else catThemeList.push(tempCatTheme);
      } else {
        delete category.selectedCategoryImage;
        //catThemeList = [...catThemeList, category];
        catThemeList.push(category);
      }
    });
    return catThemeList;
  };


  const handleImageUpload = async () => {
    let isError = false;
    if (
      !selectedBusinessImage ||
      selectedBusinessImage.size > 5242880 ||
      !selectedBusinessImage.type.includes("image")
    ) {
      setBusinessImageSelectedError(true);
      isError = true;
    }
    if (!isError) {
      let formData = new FormData();
      formData.append('fnm', CART_INITIAL_STATE.apiToken);
      formData.append("file", selectedBusinessImage);
      await ImageUploadService.upload(storeApiUrl.BACKEND_BASE_URL,getPreUrlAccordingToCart(storeCartType), formData)
        .then((response) => {
          console.log("handleImageUpload", response);
          setBusinessImageSelectedError(false);
          if (response.data.folderName) {
             handleUserThemeSave(response.data.folderName);
          } else {
            setBusinessImageSelectedError(true);
            setSnpComponent({
              ...snpComponent,
              isLoader: false,
              negativeReq: true,
              negativeAction: "Dismiss",
              positiveAction: "Try Again",
              positiveReq: true,
              showDialog: true,
              outSideClickDismiss: true,
              cancelExtra: true,
              handleSnpCancel: (()=>console.log("Dismiss")),
              handleSnpSubmit: (() => handleImageUpload()),
              subTitle: "Image is not uploaded Correctly.Try Again"
          });
          }
        })
        .catch((error) => {
          setSnpComponent({
            ...snpComponent,
            isLoader: false,
            negativeReq: true,
            negativeAction: "Dismiss",
            positiveAction: "Try Again",
            positiveReq: true,
            showDialog: true,
            outSideClickDismiss: true,
            cancelExtra: true,
            handleSnpCancel: (()=>console.log("Dismiss")),
            handleSnpSubmit: (() => handleImageUpload()),
            subTitle: "Image is not uploaded Correctly.Try Again"
        });
          console.log("handleImageUpload = > ", error);
          //window.location.href = "#/error";
          setBusinessImageSelectedError(true);
        });
    }
  };

  const handleUserTheme = async () => {
    setSnpComponent({
      ...snpComponent,
      isLoader: true,
    }); 
    
    if (selectedBusinessImage !== null && selectedBusinessImage !== "") {
      handleImageUpload();
    } else {
      handleUserThemeSave(null);
    }
  };



  const handleUserThemeSave = async (buisnessBGImage) => {
    
    let isError = false;
    let catThemeList = [];
    if (!isError) {
      catThemeList = await handleCategoryThemeListCreation();
      if (!catThemeList) isError = true;
    }
    console.log("catThemeList: ", catThemeList);
    if (!isError) {
      const url =
        storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + SAVE_USER_THEME_DATA;
      const data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          shopHash: storeActiveShopHash.id,
          id: userThemeData.id,
          businessBackgroundColor: businessTheme.businessBackgroundColor,
          businessBackgroundImage: buisnessBGImage,
          catgThemes: catThemeList,
        },
      };
      //console.log("themeDateToBeSaved: ", data);
      snpAxios
        .post(url, data, { timeout: AXIOS_TIMEOUT })
        .then((response) => {
          //console.log("savedThemeRes: ", response.data.jcJson);
          getCompleteUserThemeData();
        })
        .catch((error) => {
          setSnpComponent({
            ...snpComponent,
            isLoader: false,
          });
          console.log("errr ", error);
          //window.location.href = "#/error";
        });
    }
  };

  

  const renderDialogContent = () => {
    if (showBusinessThemeEditor) {
      return (
        <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{
          mt: 0, p: 0.5
        }}>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <TextField
              accept="image/*"
              id="businessBackgroundImage"
              name="businessBackgroundImage"
              label=""
              type="file"
              helperText="Background Image(Image should be less than 5Mb.)"
              required
              variant="filled"
              size="small"
              fullWidth
              onChange={handleCategoryThemeChange}
            /></Grid>
            <p>or</p>
          <Grid item md={12} sm={12} xs={12} lg={12}>
            <TextField
              required
              label="Select background color"
              name="businessBackgroundColor"
              type="color"
              variant="filled"
              size="small"
              fullWidth
              defaultValue={businessTheme.businessBackgroundColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          
        </Grid>
      );
    } else if (showCategoryThemeEditor) {
      return (
        <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{
          mt: 0, p: 0.5
        }}>
          {/* <Grid item md={12} sm={12} xs={12} lg={12}>
            <TextField
              accept="image/*"
              id="cardBackgroundImage"
              name="cardBackGroundImage"
              label=""
              type="file"
              helperText="Category Background Image(Image should be less than 5Mb.)"
              required
              variant="filled"
              size="small"
              fullWidth
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          <Grid md={12} sm={12} xs={12} lg={12}>
            <center>
              <p>or</p>
            </center>
          </Grid> */}
          
          <Grid item md={6} sm={6} xs={12} lg={6}>
            <TextField
              required
              label="Select Category background color"
              name="categoryBackgroundColor"
              type="color"
              variant="filled"
              size="small"
              fullWidth
              value={categoryTheme.categoryBackgroundColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          <Grid md={1} sm={1} xs={12} lg={1}>
          <center>
              <p>or</p>
            </center>
          </Grid>
          <Grid item md={5} sm={5} xs={12} lg={5}>
            <TextField
              required
              label="Enter Category background color(In hex)"
              name="categoryBackgroundColor"
              variant="filled"
              size="small"
              fullWidth
              helperText={"Example "+categoryTheme.categoryBackgroundColor}
              value={categoryTheme.categoryBackgroundColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} lg={6}>
            <TextField
              required
              label="Select Category text color"
              name="categoryFontColor"
              type="color"
              variant="filled"
              size="small"
              fullWidth
              value={categoryTheme.categoryFontColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          <Grid md={1} sm={1} xs={12} lg={1}>
          <center>
              <p>or</p>
            </center>
          </Grid>
          <Grid item md={5} sm={5} xs={12} lg={5}>
            <TextField
              required
              label="Enter Category text color(In hex)"
              name="categoryFontColor"
              variant="filled"
              size="small"
              fullWidth
              helperText={"Example "+categoryTheme.categoryFontColor}
              value={categoryTheme.categoryFontColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          
            <Grid item md={12} sm={12} xs={12} lg={12}>
            <TextField
              required
              label="Category font size"
              name="categoryFontSize"
              type="number"
              variant="filled"
              size="small"
              fullWidth
              defaultValue={categoryTheme.categoryFontSize}
              onChange={handleCategoryThemeChange}
            />
          </Grid> 
          <Grid md={12} sm={12} xs={12} lg={12}>
            <center>
            <p>***Item Theme***</p>
            </center>
          </Grid>
          
          <Grid item md={6} sm={6} xs={12} lg={6}>
            <TextField
              required
              label="Select Item default background color"
              name="itemBackgroundColor"
              type="color"
              variant="filled"
              size="small"
              fullWidth
              value={categoryTheme.itemBackgroundColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          <Grid md={1} sm={1} xs={12} lg={1}>
          <center>
              <p>or</p>
            </center>
          </Grid>
          <Grid item md={5} sm={5} xs={12} lg={5}>
            <TextField
              required
              label="Enter Item default background color(In hex)"
              name="itemBackgroundColor"
              variant="filled"
              size="small"
              fullWidth
              helperText={"Example "+categoryTheme.itemBackgroundColor}
              value={categoryTheme.itemBackgroundColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          
           <Grid item md={6} sm={6} xs={12} lg={6}>
            <TextField
              required
              label="Select Item default text color"
              name="itemFontColor"
              type="color"
              variant="filled"
              size="small"
              fullWidth
              value={categoryTheme.itemFontColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
          <Grid md={1} sm={1} xs={12} lg={1}>
          <center>
              <p>or</p>
            </center>
          </Grid>
          <Grid item md={5} sm={5} xs={12} lg={5}>
            <TextField
              required
              label="Enter Item default text color(In hex)"
              name="itemFontColor"
              variant="filled"
              size="small"
              fullWidth
              helperText={"Example "+categoryTheme.itemFontColor}
              value={categoryTheme.itemFontColor}
              onChange={handleCategoryThemeChange}
            />
          </Grid>
           <Grid item md={12} sm={12} xs={12} lg={12}>
            <TextField
              required
              label="Enter item font size"
              name="itemFontSize"
              type="number"
              variant="filled"
              size="small"
              fullWidth
              defaultValue={categoryTheme.itemFontSize}
              onChange={handleCategoryThemeChange}
            /></Grid>
        </Grid>
      );
    }
  };
  const renderDialogAction = () => {
    if (showCategoryThemeEditor) {
      return (
        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyCategoryTheme}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
        </React.Fragment>
      );
    } else if (showBusinessThemeEditor) {
      return (
        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogClose}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
        </React.Fragment>
      );
    }
  };


  const renderCardMedia = (category) => {
    //console.log("selectedCtgImage: ", category.selectedCategoryImage);
    if (
      category.selectedCategoryImage !== null &&
      category.selectedCategoryImage !== undefined
    ) {
      return (
        <CardMedia
          height={260}
          component="img"
          image={URL.createObjectURL(category.selectedCategoryImage)}
          alt={category.categoryName}
        />
      );
    } else if (
      category.cardBackGroundImage !== null &&
      category.cardBackGroundImage !== ""
    ) {
      return (
        <CardMedia
          height={260}
          component="img"
          image={storeApiUrl.BASE_IMAGE_URL + category.cardBackGroundImage}
          alt={category.categoryName}
        />
      );
    } else {
      return null;
    }
  };
  const renderCategoryCard = (category) => {
    //console.log("catCardRen: ", category);
    return (
      <Box
        sx={{
          "& .MuiCard-root": {
            position: "relative",
            backgroundColor: category.categoryBackgroundColor,
            //margin: '20px'
          },

          "& .MuiCardHeader-root": {
            position: "relative",
            zIndex: 1,
            backgroundColor: "transparent",
            textAlign: "center",
          },
          "& .MuiCardHeader-title": {
            //could also be placed inside header class
            color: category.categoryFontColor,
            fontSize: `${category.categoryFontSize}px`,
            backgroundColor: "transparent",
          },
          "& .MuiCardMedia-root": {
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
          },
          "& .MuiCardContent-root": {
            position: "relative",
            zIndex: 1,
            backgroundColor: "transparent",
            "& p.MuiTypography-root": {
              fontSize: `${category.itemFontSize}px`,
              color: category.itemFontColor,
              backgroundColor: category.itemBackgroundColor,
            },
            "& p.MuiTypography-body2": {
              fontSize: `${category.itemFontSize}px`,
              color: category.itemFontColor,
              backgroundColor: category.itemBackgroundColor,
            },
          },
          "& .MuiCardActions-root": {
            //position: "relative",
          },
        }}
      >
        <Card style={{ maxWidth: 845, minHeight: 150 }} elevation={10}>
          <CardHeader title={category.categoryName} />
          {renderCardMedia(category)}

          <CardContent>
            <Typography
              variant="body2"
              component="p"
              gutterBottom
            >
              Sample Item 1
              <Typography
              variant="body2"
              //className={classes.fiCardContentTextSecondary}
              component="p"
              gutterBottom
            >
              Sample Item 2
            </Typography>
            </Typography>
            
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setShowCategoryThemeEditor(true);
                setCategoryTheme({ ...categoryTheme, ...category });
              }}
            >
              Edit Theme
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => handleResetCategoryTheme(category.categoryId)}
            >
              Reset Theme
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  };

  


  
  const renderSnpContent = () => {
    return (
      <Box
        sx={{
          "& .MuiGrid-container": {
            padding: "55px 40px",
            justifyContent: "space-around",
          },
          "& .MuiCard-root": {
            position: "relative",
            backgroundColor: businessTheme.backgroundColor,
          },
          "& .MuiCardMedia-root": {
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
          },
          "& .MuiButtonBase-root": {
            margin: "2px 10px",
          },
          minHeight: 600,
          bgcolor: businessTheme.businessBackgroundColor,
          display: "flex",
          //justifyContent: "center",
          position: "relative",
          //backgroundImage: businessTheme.businessBackgroundImage,
          backgroundImage:
            selectedBusinessImage !== null && selectedBusinessImage !== undefined
              ? `url(${URL.createObjectURL(selectedBusinessImage)})`
              : `url(${storeApiUrl.BASE_IMAGE_URL + businessTheme.businessBackgroundImage})`,
        }}
      >
        <Grid container spacing={6}>
          {isDataLoaded
            ? Object.values(categoryThemeList).map((category) => {
              return (
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  item
                  key={category.categoryId}
                >
                  {renderCategoryCard(category)}
                </Grid>
              );
            })
            : ""}
        </Grid>
        <DialogBox
          showDialog={showBusinessThemeEditor || showCategoryThemeEditor}
          title={(showBusinessThemeEditor && "Change Background Colour/Image") || (showCategoryThemeEditor && "Change Cateogory Colour/Image")}
          dialogContent={renderDialogContent()}
          dialogActions={renderDialogAction()}
          onClose={handleDialogClose}
        />
        <FAB actions={fabActions}/>
      </Box>
    );
  };
  const handleSnackClose = () => {
    setSnpComponent({
      ...snpComponent,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };
  return (
    <React.Fragment>
      <SnpComponent
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        handleDialogClose={snpComponent.handleSnpDialogClose}
        iconReq={snpComponent.iconReq}
        iconContent={snpComponent.iconContent}
        onCancel={snpComponent.handleSnpCancel}
        handleSubmit={snpComponent.handleSnpSubmit}
        showDialog={snpComponent.showDialog}
        content={renderSnpContent()}
        onSnackClose={handleSnackClose}
        isLoader={snpComponent.isLoader}
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss} />
    </React.Fragment>
  );
}

export default ThemeManager;
