import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import SnpComponent from "../global/SnpComponent";
import { ECARTGETSHOPCATG, ECARTGETSHOPITEM, EcartUrlQuery, SUCCESS, isEmpty } from "../../constants/constant";
import { Avatar, Badge, Box, Card, CardHeader, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from "react-redux";
export default function EcartWeb() {
    const location = useLocation();
    const navigate = useNavigate();
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [initialBg, setInitialBg] = useState({
        background: "",
        image: true,
    })

    const [listCateogory, setListCateogory] = useState([]);
    const [listProduct, setListProduct] = useState([]);

    useEffect(() => {
        // const handleBeforeUnload = () => {
        //     // Remove the item from sessionStorage
        //     console.log("handleBeforeUnload")
        //     sessionStorage.removeItem(EcartUrlQuery.SHOPHASH);
        // }; const handleUnload = () => {
        //     // Remove the item from sessionStorage
        //     console.log("handleUnload")
        //     sessionStorage.removeItem(EcartUrlQuery.SHOPHASH);
        // };
        // // Attach the event listeners when the component mounts
        // window.addEventListener('beforeunload', handleBeforeUnload);
        // window.addEventListener('unload', handleUnload);

        //Check For Query  Params
        const queryParams = new URLSearchParams(location.search);
        const myParam = queryParams.get(EcartUrlQuery.SHOPHASH);
        //console.log(EcartUrlQuery.SHOPHASH,myParam); // Read the value of 'myParam' from the URL
        if (!isEmpty(myParam)) {
            // Remove query parameters
            queryParams.delete(EcartUrlQuery.SHOPHASH);
            sessionStorage.setItem('q', myParam);
            //console.log("!isempty")
            // Update the URL without the query parameter
            navigate(`?${queryParams.toString()}`, { replace: true });
            getEcartShopCateogory(myParam);
        } else {
            const shopHash = sessionStorage.getItem(EcartUrlQuery.SHOPHASH); //ShopHash
            //console.log("sessionStorage",shopHash); // Read the value of 'myParam' from the URL
            if (!isEmpty(shopHash)) {
                getEcartShopCateogory(shopHash);
            } else {
                //Panic fallback to Snp WebSite
                window.location.href = 'http://www.snpnextgen.com';
            }
        }
        // return () => {
        //     window.removeEventListener('beforeunload', handleBeforeUnload);
        //     window.removeEventListener('unload', handleUnload);
        // };
    }, [storeApiUrl]);

    const getEcartShopCateogory = async (myParam) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPCATG;
        let data = {
            jcJson: {
                shopHash: myParam,
            }
        };

        //console.log("getEcartShopCateogory  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //console.log("getEcartShopCateogory <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setInitialBg({
                        ...initialBg,
                        background: response.data.jcJson.background,
                        image: response.data.jcJson.image,
                    });
                    setListCateogory(response.data.jcJson.value_2);
                }
                getEcartShopProduct(myParam);
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getEcartShopProduct = async (myParam) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPITEM;
        let data = {
            jcJson: {
                shopHash: myParam,
            }
        };

        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("getEcartShopProduct <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setListProduct(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };



    const getCatgBackGroundColor = (product) => {
        //console.log(product.categoryName,product.catgBackground +"  "+product.image);
        if (product.image)
            return "";
        return product.catgBackground;
    };

    const getCatgBackGroundImage = (product) => {
        if (!product.image)
            return "";
        return storeApiUrl.BASE_IMAGE_URL + product.catgBackground;
    };
    const getPriceDets = (product) => {
        if (!isEmpty(product.priceName))
            return "\u20B9 " + product.price + " (" + product.priceName + ")";
        return "\u20B9 " + product.price;
    }
   
    const renderProductCard = (product) => {

        return (
            <Box
                sx={{
                    "& .MuiCard-root": {
                        position: "relative",
                        backgroundColor: getCatgBackGroundColor(product),
                        //margin: '20px'
                    },

                    "& .MuiCardHeader-root": {
                        position: "relative",
                        zIndex: 1,
                        backgroundColor: "transparent",
                        textAlign: "center",
                    },
                    "& .MuiCardHeader-title": {
                        //could also be placed inside header class
                        color: product.categoryFontColor,
                        fontSize: `${product.categoryFontSize}px`,
                        backgroundColor: "transparent",
                    },
                    "& .MuiCardMedia-root": {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: "100%",
                        width: "100%",
                    },
                    "& .MuiCardContent-root": {
                        position: "relative",
                        zIndex: 1,
                        backgroundColor: "transparent",
                        "& p.MuiTypography-body1": {
                            fontSize: `${product.defItemFontSize}px`,
                            color: product.defItemFontColor,
                            backgroundColor: product.defItemBackgroundColor,
                        },
                        "& p.MuiTypography-body2": {
                            fontSize: `${product.defRateFontSize}px`,
                            color: product.defItemFontColor,
                            backgroundColor: product.defItemBackgroundColor,
                        },
                        "& p.MuiTypography-body3": {
                            fontSize: `${product.defIngrFontSize}px`,
                            color: product.defItemFontColor,
                            backgroundColor: product.defItemBackgroundColor,
                        },
                    },
                    "& .MuiCardActions-root": {
                        //position: "relative",
                    },
                }}
            >
                <Card style={{ maxWidth: 845, minHeight: 150 }} elevation={10}>
                    <CardHeader title={product.categoryName} />
                    <Grid container spacing={0}>
                        {
                            product.productImage[0] && <Grid item xs={12} md={4} lg={4} sm={12}>
                                <img
                                    alt=""
                                    style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                                    src={storeApiUrl.BASE_IMAGE_URL + product.productImage[0]}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={8} lg={8} sm={12}>
                            <Typography
                                variant="body1"
                                component="p"
                                gutterBottom style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            ><u>{product.name}</u></Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                gutterBottom style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            >{getPriceDets(product)}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        );
    };
    const avatarStyle = { backgroundColor: "#bdb7b7" };
    
    const [anchorElUser, setAnchorElUser] = useState(false);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null);
      };
      const settings = ['Customer Name', 'Cart', 'Sign Out'];
    const renderSnpContent = () => {
        return (
            <div>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                        <div style={{ float: 'right',margin:"1px", }}>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Badge onClick={handleOpenUserMenu} color="secondary"> <Avatar  onClick={handleOpenUserMenu} style={avatarStyle}>
                                            <PersonIcon onClick={handleOpenUserMenu}/>
                                        </Avatar> </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} id={setting} sx={{
                                            "&:hover": {
                                                backgroundColor: "#00333e",
                                                color: "white",
                                            },
                                        }} onClick={handleCloseUserMenu}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </div>
                    </Grid>

                <Box
                    sx={{
                        "& .MuiGrid-container": {
                            padding: "55px 40px",
                            justifyContent: "space-around",
                        },
                        "& .MuiCard-root": {
                            position: "relative",
                            ...(!initialBg.image && {
                                backgroundColor: "transparent",
                            })
                        },
                        "& .MuiCardMedia-root": {
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: "100%",
                            width: "100%",
                        },
                        "& .MuiButtonBase-root": {
                            //margin: "1px 20px",
                        },
                        margin: "18px 0px 1px 0px",
                        borderRadius: "10px",
                        minHeight: 600,
                        ...(!initialBg.image && {
                            bgcolor: initialBg.background,
                        }),
                        display: "flex",
                        position: "relative",
                        backgroundImage: initialBg.image && `url(${storeApiUrl.BASE_IMAGE_URL + initialBg.background})`,
                    }}
                >
                    <Grid container spacing={6}>
                        {!snpComponent.isLoader && Object.values(listProduct).map((product) => {
                            return (
                                <Grid xs={12} sm={6} md={4} lg={4} item key={product.id}>
                                    {renderProductCard(product)}
                                </Grid>
                            );
                        })
                        }
                    </Grid>
                </Box> </Grid></div>
        )
    };
    return (
        <React.Fragment>
            <SnpComponent content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                isLoader={snpComponent.isLoader}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss} />
        </React.Fragment>
    );
}