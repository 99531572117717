import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Typography, Paper, Autocomplete, Tooltip, Snackbar } from "@mui/material";
import Loader from "../global/loader/Loader";
import "./Profile.css";
import {
    UPDATE_PROFILE,
    setProileResponse,
    SUCCESS,
    OfferType,
    CART_INITIAL_STATE, CartDets,
    isRoleAllowed,
    ADMINRIGHTS,
    INITIAL_SHOP_HASH,
    GETSINGLECONFIGS,
    CUSTLOYALITYPOINT,
    getPreUrlAccordingToCart,
    UPDATEACTIVESHOPSESSION,
    printDebugLog,
} from "../../constants/constant";
import axios from "axios";
import {
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { setCartType, setActiveShopHash } from "../../actions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { snpAxios } from '../global/api';
export default function Profile({ setComponentIndex }) {

    const dispatch = useDispatch();
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);

    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isNotAllowed, setNotAllowed] = useState(false);
    const [pointCostAllowed, setPointCostAllowed] = useState(false);
    const [profileData, setProfileData] = useState({
        userEmail: "",
        companyName: "",
        userName: "",
        companyWebAddress: "",
        companyMail: "",
        validity: "",
        startDate: 0,
        loginBackGroundImage: "",
        pointDenom: 0,
        pointDenomVal: 0.0,
        pointValue: 0.0,
        pointRealCostPer: 0.0,
    });
    const [selShop, setSelShop] = useState(INITIAL_SHOP_HASH);
    const [loyaityPoints, setLoyaityPoints] = useState(false);
    const setPointCostValue = (value) => {
        //console.log("setPointCostValue",value)
        if (parseInt(value) === 1)
            setPointCostAllowed(false);
        else
            setPointCostAllowed(true);
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.log("handleInputChange", name, value);
        if (name === 'pointDenom') {
            setPointCostValue(value);
        }
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    const getPointCost = () => {
        let data = (isNotAllowed || !pointCostAllowed);
        //console.log("getPointCost",isNotAllowed,pointCostAllowed,data);
        return data;
    }

    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === CUSTLOYALITYPOINT)
                        setLoyaityPoints(response.data === 'Yes')
                }
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const handleProfileSubmit = async () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + UPDATE_PROFILE;
        setDataLoaded(false);
        //console.log(jsonObject);
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                companyWebAddress: profileData.companyWebAddress,
                shopBackgroundImage: profileData.loginBackGroundImage,
                pointDenom: parseInt(profileData.pointDenom),
                pointDenomVal: parseFloat(profileData.pointDenomVal),
                pointValue: parseFloat(profileData.pointValue),
                pointRealCostPer: parseFloat(profileData.pointRealCostPer),
            }
        };
        // console.log("handleProfileSubmit filledData => ", filledData);
        snpAxios.post(catgUrl, filledData)
            .then(response => {
                setDataLoaded(true);
                //console.log("responses => ", response.data);
                if (response.data.status === SUCCESS) {
                    setProileResponse(profileData);
                    //console.log('Printing Login Details : ', CART_INITIAL_STATE);
                }
            }).catch(error => {
                setDataLoaded(true);
                console.log("error : ", error);
                //window.location.href = "#/error";
            });
    };


    useLayoutEffect(() => {
        //console.log('Printing storeActiveShopHash : ', storeCartType,storeActiveShopHash);
        getSingleConfigs(CUSTLOYALITYPOINT);
        setNotAllowed(!isRoleAllowed(ADMINRIGHTS));
        setPointCostValue(CART_INITIAL_STATE.pointMethod.pointDenom);
        setSelShop(storeActiveShopHash);
        setProfileData({
            ...profileData,
            userEmail: CART_INITIAL_STATE.userEmail,
            companyName: CART_INITIAL_STATE.companyName,
            userName: CART_INITIAL_STATE.userName,
            companyWebAddress: CART_INITIAL_STATE.companyWebAddress,
            companyMail: CART_INITIAL_STATE.companyMail,
            validity: CART_INITIAL_STATE.validity,
            startDate: CART_INITIAL_STATE.startDate,
            pointDenom: CART_INITIAL_STATE.pointMethod.pointDenom,
            pointDenomVal: CART_INITIAL_STATE.pointMethod.pointDenomVal,
            pointValue: CART_INITIAL_STATE.pointMethod.pointValue,
            pointRealCostPer: CART_INITIAL_STATE.pointMethod.pointRealCostPer,
        });
        setDataLoaded(true);
    }, [storeCartType, storeActiveShopHash, storeApiUrl]);

    const getKeyByValue = (CartDets, value) => {
        //console.log("getKeyByValue",CartDets,value);
        return value.location + "(" + Object.keys(CartDets).find(key => CartDets[key] === value.cartType) + ")";
    }

    const updateCartValue = (event, selectedHash) => {
        //let selectedHash = e.target.value;
        if (selectedHash != null) {
            //console.log("updateCartValue",selectedHash);


            let cartUrl = storeApiUrl.BACKEND_BASE_URL + UPDATEACTIVESHOPSESSION;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    shopHash: selectedHash.id,
                },
            };
            //  console.log("getRestoDetails  => ",data);
            snpAxios
                .post(cartUrl, data)
                .then((response) => {
                    // console.log("response  => ", response.data);
                    if (response.data.status === SUCCESS) {
                        dispatch(setCartType(selectedHash.cartType));
                        let responseShopHash = selectedHash;
                        responseShopHash.defScreen = response.data.jcJson.defScreen;
                        responseShopHash.companyName = response.data.jcJson.companyName;
                        responseShopHash.companyWebAddress = response.data.jcJson.companyWebAddress;
                        responseShopHash.companyMail = response.data.jcJson.companyMail;
                        responseShopHash.packages = response.data.jcJson.packages;
                        responseShopHash.onlineDelvMethod = response.data.jcJson.onlineDelvMethod;
                        responseShopHash.validity = response.data.jcJson.validity;
                        responseShopHash.startDate = response.data.jcJson.startDate;
                        responseShopHash.defBalanceType = response.data.jcJson.defBalanceType;
                        dispatch(setActiveShopHash(responseShopHash));
                        setComponentIndex(responseShopHash.defScreen);
                        setSnackBar({
                            ...snackBar, snackBarMsg: "Changed to " + selectedHash.shop + " successfully done.", snackBarOpen: true,
                        })
                    } else {
                        setSnackBar({
                            ...snackBar, snackBarMsg: "Changed to " + selectedHash.shop + " not happen.Try again!", snackBarOpen: true,
                        })
                    }
                })
                .catch((error) => {
                    printDebugLog("errr ", error);
                    //window.location.href = "#/error";
                });



        }

        //dispatch(setCartType(selectedHash.cartType));
        //let index = parseInt(e.target.value) -1;
        //let activeHash = CART_INITIAL_STATE.listShopHash[index];
        //console.log("updateCartValue",index,activeHash);
        // dispatch(setActiveShopHash(selectedHash));
        //console.log(e.target.value);
        //setComponentIndex((selectedHash.cartType === CartDets.Resto) ? 7 : 0);
    }

    const INITIAL_SNACK_BAR = {
        snackBarOpen: false, snackBarMsg: ""
    };

    const [snackBar, setSnackBar] = useState(INITIAL_SNACK_BAR);
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBar(INITIAL_SNACK_BAR);
    };

    return (
        <React.Fragment>
            <Snackbar
                open={snackBar.snackBarOpen}
                autoHideDuration={3000}
                message={snackBar.snackBarMsg}
                onClose={handleSnackClose}
            />
            {
                isDataLoaded ?
                    <div >

                        <Grid container spacing={1}>
                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                <Paper
                                    elevation={0}
                                    autoHeight
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "background.paper",
                                        boxShadow: 9,
                                        borderRadius: 2,
                                        padding: 2,
                                    }}
                                >
                                    <Grid container spacing={1} justifyContent="center"
                                        alignItems="center" sx={{
                                            padding: 0,
                                        }}>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="companyName"
                                                label="Company Name"
                                                name="companyName"
                                                placeholder="Company Name"
                                                defaultValue={profileData.companyName}
                                                fullWidth
                                                variant="filled"
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="userName"
                                                label="Name"
                                                variant="filled"
                                                size="small"
                                                name="userName"
                                                placeholder="Name"
                                                defaultValue={profileData.userName}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="userEmail"
                                                label="Email"
                                                name="userEmail"
                                                placeholder="E-mail Address"
                                                defaultValue={profileData.userEmail}
                                                fullWidth
                                                variant="filled"
                                                size="small"

                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled={!isRoleAllowed(ADMINRIGHTS)}
                                                id="companyWebAddress"
                                                label="Web Address"
                                                name="companyWebAddress"
                                                placeholder="Web Address"
                                                defaultValue={profileData.companyWebAddress}
                                                fullWidth
                                                variant="filled"
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="startDate"
                                                label="Start Date"
                                                name="startDate"
                                                defaultValue={profileData.startDate}
                                                fullWidth
                                                variant="filled"
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="validity"
                                                label="Remaining Validity"
                                                name="validity"
                                                defaultValue={profileData.validity + " days"}
                                                fullWidth
                                                variant="filled"
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                <Paper
                                    elevation={0}
                                    autoHeight
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "background.paper",
                                        boxShadow: 9,
                                        borderRadius: 2,
                                        padding: 2,
                                    }}>
                                    <Grid container spacing={1} display="flex"
                                        alignItems="center" sx={{
                                            padding: 0.1,
                                        }}>
                                        {CART_INITIAL_STATE.listShopHash.length > 1 &&
                                            <Grid item md={4} xs={12} lg={4} sm={12}>
                                                <Autocomplete
                                                    id="demo-simple-select-label"
                                                    variant="filled"
                                                    size="small"
                                                    autoHighlight
                                                    options={CART_INITIAL_STATE.listShopHash}
                                                    onChange={updateCartValue}
                                                    getOptionLabel={(option) => getKeyByValue(CartDets, option)}
                                                    defaultValue={storeActiveShopHash}
                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            {...props}
                                                        >
                                                            {
                                                                storeActiveShopHash.id === option.id ?
                                                                    <Typography textAlign="center" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: "#2f1160", fontWeight: 'bold' }}>{getKeyByValue(CartDets, option)}</Typography>
                                                                    : <Typography textAlign="center" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{getKeyByValue(CartDets, option)}</Typography>
                                                            }
                                                        </Box>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Shop Type"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                            }}
                                                            style={{ width: "100%" }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        }
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="shopName"
                                                label="Shop Name"
                                                name="shopName"
                                                placeholder="Shop Name"
                                                defaultValue={selShop.shop}
                                                fullWidth
                                                variant="filled"
                                                size="small"

                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="shopAddress"
                                                label="Shop Address"
                                                variant="filled"
                                                size="small"
                                                multiline
                                                name="shopAddress"
                                                onChange={handleInputChange}
                                                placeholder="Shop Address"
                                                defaultValue={selShop.location}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={6} lg={4} sm={4}>
                                            <TextField
                                                disabled
                                                id="gstDetails"
                                                label="Gst Details"
                                                variant="filled"
                                                size="small"
                                                name="gstDetails"
                                                onChange={handleInputChange}
                                                placeholder="GST Details"
                                                defaultValue={selShop.gstDetail}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {loyaityPoints &&
                                <Grid item md={12} xs={12} lg={12} sm={12}>
                                    <Paper
                                        elevation={0}
                                        autoHeight
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            backgroundColor: "background.paper",
                                            boxShadow: 9,
                                            borderRadius: 2,
                                            padding: 2,
                                        }}
                                    >
                                        <Grid container spacing={1} display="flex"
                                            alignItems="center" sx={{
                                                padding: 0.1,
                                            }}>
                                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                                <FormControl component="fieldset">

                                                    <FormLabel component="legend">
                                                        Set Loylity Point Formula
                                                    </FormLabel>
                                                    <RadioGroup
                                                        aria-label="pointDenom"
                                                        name="pointDenom"
                                                        value={profileData.pointDenom.toString()}
                                                        onChange={handleInputChange}
                                                        row={true}

                                                    >
                                                        <FormControlLabel
                                                            disabled={isNotAllowed}
                                                            value={OfferType.No_Offer.toString()}
                                                            control={<Radio />}
                                                            label="No Formula"
                                                            fullwidth

                                                        />
                                                        <FormControlLabel
                                                            disabled={isNotAllowed}
                                                            value={OfferType.Flat.toString()}
                                                            control={<Radio />}
                                                            label="Flat"
                                                            fullWidth

                                                        />
                                                        <FormControlLabel
                                                            disabled={isNotAllowed}
                                                            value={OfferType.Percentage.toString()}
                                                            control={<Radio />}
                                                            label="Percentage"
                                                            fullwidth
                                                        />
                                                        <FormControlLabel
                                                            disabled={isNotAllowed}
                                                            value={OfferType.ONVALUE_FLAT.toString()}
                                                            control={<Radio />}
                                                            label="Flat Per Some Value"
                                                            fullwidth
                                                        />
                                                        <FormControlLabel
                                                            disabled={isNotAllowed}
                                                            value={OfferType.ONVALUE_PERC.toString()}
                                                            control={<Radio />}
                                                            label="Percentage Per Some Value"
                                                            fullWidth
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {
                                                (profileData.pointDenom > 1) &&
                                                <Grid item md={4} xs={6} lg={4} sm={4}>
                                                    <TextField
                                                        disabled={isNotAllowed}
                                                        id="pointValue"
                                                        name="pointValue"
                                                        variant="filled"
                                                        size="small"
                                                        label="Enter Point Value"
                                                        value={profileData.pointValue.toString()}
                                                        onChange={handleInputChange}
                                                        fullwidth
                                                    />
                                                </Grid>
                                            }

                                            {
                                                (profileData.pointDenom > 3) &&
                                                <Grid item md={4} xs={6} lg={4} sm={4}>
                                                    <TextField
                                                        disabled={isNotAllowed}
                                                        id="pointDenomVal"
                                                        name="pointDenomVal"
                                                        variant="filled"
                                                        size="small"
                                                        label="Enter Point Denomination Value"
                                                        value={profileData.pointDenomVal.toString()}
                                                        onChange={handleInputChange}
                                                        fullwidth
                                                    />
                                                </Grid>
                                            }
                                            <Grid item md={4} xs={12} lg={4} sm={4}>
                                                <TextField
                                                    disabled={getPointCost()}
                                                    id="pointRealCostPer"
                                                    label="Enter Per Point Cost(Rs)"
                                                    variant="filled"
                                                    size="small"
                                                    name="pointRealCostPer"
                                                    onChange={handleInputChange}
                                                    defaultValue={profileData.pointRealCostPer.toString()}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            }


                            <Grid item md={12} xs={12} lg={12} sm={12}>
                                <Paper
                                    elevation={0}
                                    autoHeight
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "background.paper",
                                        boxShadow: 9,
                                        borderRadius: 2,
                                        padding: 2,
                                    }}>
                                    <Grid container spacing={2} display="flex" alignItems="flex-start"
                                        sx={{
                                            padding: 0.1, ml: 2
                                        }}>
                                        <Grid md={12} xs={12} lg={12} sm={12}>
                                            <Typography variant="h5" fontWeight="bold" style={{ color: "rgb(189, 81, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}><u>Feature Provided to User:</u></Typography>
                                        </Grid>
                                        <Grid md={12} xs={12} lg={12} sm={12}>
                                            <Grid container spacing={2}>
                                                {
                                                    Object.keys(CART_INITIAL_STATE.packages).map((key) => (
                                                        <Grid item md={6} xs={12} lg={6} sm={6}>
                                                            <tr key={key}>
                                                            <td>
                                                                <Typography variant="p" fontWeight="bold" style={{ color: "#135f2c", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >
                                                                    {key}
                                                                </Typography>

                                                                {
                                                                    CART_INITIAL_STATE.packages[key] ? <Tooltip title="Subscribed">
                                                                        <CheckCircleIcon />
                                                                    </Tooltip> :
                                                                        <Tooltip title="Not Subscribed.Ask your agent to subscribe">
                                                                            <CancelIcon />
                                                                        </Tooltip>
                                                                }

                                                            </td>
                                                        </tr>
                                                        </Grid>
                                                        
                                                        
                                                        
                                                    ))
                                                }
                                            </Grid>
                                        </Grid></Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justifyContent="center"
                            alignItems="center" sx={{
                                padding: 0, mt: 2,
                            }}>
                            <Grid item md={3} xs={12} lg={3} sm={3}>
                                <Button
                                    variant="contained" color="primary" onClick={handleProfileSubmit}>
                                    Update Profile
                                </Button>
                            </Grid>

                            {
                                storeCartType === CartDets.Resto &&
                                <Grid item md={3} xs={12} lg={3} sm={3}>
                                    <Button
                                        variant="contained" color="primary" onClick={() => setComponentIndex(16)}>
                                        Table Configuration
                                    </Button>  </Grid>
                            }
                        </Grid>
                    </div>
                    : <Loader />
            }

        </React.Fragment>
    );
}
