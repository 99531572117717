import { SNP_ENCODER, SNP_JUMPLED, printLog } from "../constants/constant";

class utilities {
    jumbleString(input) {
      const level = input.length / SNP_JUMPLED;
      printLog("jumbleString",SNP_JUMPLED);
      let parts = [input.substring(0, level), input.substring(level)];
      return (
        parts[0].split("").reverse().join("") +
        parts[1].split("").reverse().join("")
      );
    }
    encode(input) {
      input = this.jumbleString(input);
      let word = "";
      for (let index = 0; index < input.length; index++) {
        let c = input.charCodeAt(index) + Number(SNP_ENCODER);
        word = word + String.fromCharCode(c);
      }
      return word;
    }
  }
  
  export default new utilities();
  