import React from "react";
import Routing from "./Routing";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { SnpWrapper } from "./global/api";



function App() {

  const theme = createTheme({
    // "@global": {
    //   body: {
    //     backgroundColor: 'green'

    //   }
    // },
    typography: {
      fontFamily: 'Verdana',
    },
    palette: {
      primary: {
        main: "#01333e",
        paperborder: "#ebebe0",
        datagridrowback: "#f5f5f5",
        datagridrow: "#00333e",
        datagridCol: "#608a97",
      },
      secondary: {
        main: "#2f1160",
      },
      background: {
        paper: "#ffffff",
        default: "#ebebe0",
      },
      dRed: {
        main: "#8b0000",
      },
      lightBlue: {
        main : "#aa97dd",
      },
      bRed: {
        main: "#EE4B2B",
        paperborder: "#ebebe0",
        datagridrowback: "#f5f5f5",
        datagridrow: "#00333e",
        datagridCol: "#608a97"
      },
      brown:"#331a00",
      lightbrown:"#C4A484",
    },
    components: {
      // Name of the component
      MuiContainer: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: "#ebebe0",
          },
        },
      },
    },
  });

  

  // const fetchOfflineUrl = async () => {
  //   const files = await Promise.all(
  //     SNPOFFLINEFILE.map((link) => fetch(link).then((res) => res.text()))
  //   );
  //   setApiUrl(setUrl(files[0]));
  // }

  
  return (
    <ThemeProvider theme={theme}>
        <SnpWrapper>
          <Routing />
        </SnpWrapper>
    </ThemeProvider>
  );
}

export default App;
